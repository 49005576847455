/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Gistology_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "гистология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Gender_Bar from "../../components/Gender_Bar";
import Gistology_Data_EntriesList from "../../components/Gistology/Gistology_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { Gistology_EntriesPerPage } from './Gistology_Categories_Page';

const Gistology_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Gistology_EntriesPerPage, user.gender == 3 ? null : user.gender).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.items)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/histology_icon.png' alt='page_title_icon'></img>{t("Histology_title")}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <div className="mt-2">
                <div md={9}>
                    {/* <Gender_Bar onGenderChanged={(gender) => user.gender = gender} /> */}
                    <Gistology_Data_EntriesList pageData={pageData} />
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </Container>;</>
});

export default Gistology_Data_EntriesTable_Page;
