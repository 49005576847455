/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Terms_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница терминов отвечает за отображение страницы терминов и соглашений лицензирования.
 *	@author: Манжос Геннадий Юрьевич,   Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import './Terms_Page.css';

const Terms_Page = observer(() => {
    const history = useNavigate();
    const { t } = useTranslation();


    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    function openMenu(evt, selectedName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("terms_main_block_selected");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("terms_sidebar_menu");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(selectedName).style.display = "block";
        evt.currentTarget.className += " active";

        setSidebarOpen(false);
    };

    useEffect(() => {
        const fakeEvent = { currentTarget: { className: "" } };
        openMenu(fakeEvent, 'Общие сведения');
    }, []); // Пустой массив зависимостей означает, что эффект будет выполнен только при первом рендере


    return <>
         <div className='terms_page'>
    <div className='terms_grid'>
        <div className={`terms_sidebar ${isSidebarOpen ? 'open' : 'hidden'}`}>
            <div className='terms_sidebar_block'>
                <button className='close-button'
                onClick={toggleSidebar}>
                    &#10005;
                </button>
                <div className='terms_side_sidebar_content'>
                    <img src='/icons/terms/Component 15.png' alt="Maintenance" />
                    {t('Maintenance')}
                </div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Общие сведения')}>{t('General information')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Основные разработки документа')}>{t('Main Document Developments')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Срок действия инструкции')}>{t('Validity period of the instructions')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Категории медицинского атласа')}>{t('Categories of the medical atlas')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Демо-версия')}>{t('Demo version')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Мобильная версия')}>{t('Mobile version')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Система поиска в категориях')}>{t('Search system')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Настройка аккаунта')}>{t('Account settings')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Приобретение подписки')}>{t('Subscription')}</div>
                <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Техническая поддержка пользователей')}>{t('Technical support for users')}</div>
            </div>
        </div>
        <div className='terms_main'>
            <div className='terms_main_title'>{t('User Instructions')}</div>
                <div
                    className={`terms_sidebar_content ${isSidebarOpen ? 'hidden' : ''}`}
                    onClick={toggleSidebar}>
                    <img
                        src='/icons/terms/Component 15.png'
                        className='terms_sidebar_content_img'
                        alt="Maintenance"
                    />
                    {t('Maintenance')}
                </div>
            <div className='terms_main_block'>
                        <div className='terms_main_block_selected' id="Общие сведения">
                            <button className='user_guide_block_button'
                                onClick={() => window.open(t('User_guide_pdf'), "_blank")}
                            >
                                <img src='/icons/button_pdf_file.svg' />
                                <br></br>
                                <br></br>
                                {t("pdf_open")}
                            </button>
                            <div className='terms_main_name_title' >{t('General information')}</div>
                            <div className='terms_main_desc'>
                                {t("The web application is designed")}
                                <br></br>
                                <br></br>
                                <ul>
                                    <li>{t('ECG_title')}</li>
                                    <li>{t('Endoscopy_title')}</li>
                                    <li>{t('Path_title')}</li>
                                    <li>{t('Bio_title')} ({t('Protozoology')}, {t('Helminthology')}, {t('Medical_entomology')})</li>
                                    <li>{t('Histology_title')}</li>
                                    <li>{t('Clinical_lab_diagnistics')}</li>
                                    <li>{t('Luch_title')}</li>
                                    <li>{t('microbiology_title')}</li>
                                    <li>{t('genetics_title')}</li>
                                    <li>{t('Botanica')}</li>
                                    <li>{t('Dentistry')}</li>
                                    <li>{t('Skin_diseases')}</li>
                                    <li>{t("Ultrasound_diagnostics")}</li>
                                    <li>{t("Maps_title")}</li>
                                    <li>{t("Ophthalmoscopy_title")}</li>
                                    <li>{t("reabilitation_title")}</li>
                                    <li>{t("modelling_title")}</li>
                                    <li>{t("otolaryngology_title")}</li>
                                    <li>{t("Anatomy")}</li>
                                    <li>{t("eeg_title")}</li>
                                    <li>{t("ortopedia_title")}</li>
                                    <li>{t("pharmacy_title")}</li>
                                    <li>{t("auscultia_title")}</li>
                                    <li>{t("biology_title")}</li>
                                </ul>
                                {t('The area of application')}                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Основные разработки документа">
                            <div className='terms_main_name_title'>1. {t('Main Document Developments')}</div>

                            <div className='terms_main_desc'>
                                {t('This instruction is developed')}
                                <br></br>
                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Срок действия инструкции">
                            <div className='terms_main_name_title'>2. {t('Validity period of the instructions')}</div>
                            <div className='terms_main_desc'>
                                {t('2.1')}                                <br></br>
                                <br></br>
                                {t('2.2')}                              </div>
                        </div>
                        <div className='terms_main_block_selected' id="Категории медицинского атласа">
                            <div className='terms_main_name_title'>4. {t('Categories of the medical atlas')}</div>
                            <img className="screen_menu" src={t('screens_menu')}></img>

                            <div className='screen_string'>{t('Figure 3.3')}</div>
                            <div className='terms_main_desc'>
                                {t("The atlas includes many categories")}
                                <br></br>
                                <br></br>
                                <ul>
                                    <b>
                                    <li>{t('ECG_title')}</li>
                                    <li>{t('Endoscopy_title')}</li>
                                    <li>{t('Path_title')}</li>
                                    <li>{t('Bio_title')} ({t('Protozoology')}, {t('Helminthology')}, {t('Medical_entomology')})</li>
                                    <li>{t('Histology_title')}</li>
                                    <li>{t('Clinical_lab_diagnistics')}</li>
                                    <li>{t('Luch_title')}</li>
                                    <li>{t('microbiology_title')}</li>
                                    <li>{t('genetics_title')}</li>
                                    <li>{t('Botanica')}</li>
                                    <li>{t('Dentistry')}</li>
                                    <li>{t('Skin_diseases')}</li>
                                    <li>{t("Ultrasound_diagnostics")}</li>
                                    <li>{t("Maps_title")}</li>
                                    <li>{t("Ophthalmoscopy_title")}</li>
                                    <li>{t("reabilitation_title")}</li>
                                    <li>{t("modelling_title")}</li>
                                    <li>{t("otolaryngology_title")}</li>
                                    <li>{t("Anatomy")}</li>
                                    <li>{t("eeg_title")}</li>
                                    <li>{t("ortopedia_title")}</li>
                                    <li>{t("pharmacy_title")}</li>
                                    <li>{t("auscultia_title")}</li>
                                    <li>{t("biology_title")}</li>
                                    </b>

                                </ul>
                            </div>
                            <div className='terms_main_desc'>
                                <h3><b>4.1. {t('ECG_title')}</b></h3>
                                <br></br>
                                {t('Includes descriptions of ECG cases')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_filters')}></img>
                                <div className='screen_string'>{t('Figure 4.1')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_menu')}></img>
                                <div className='screen_string'>{t('Figure 4.2')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_case')}></img>
                                <div className='screen_string'>{t('Figure 4.3')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_table')}></img>
                                <div className='screen_string'>{t('Figure 4.4')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_ekg_photos')}></img>
                                <div className='screen_string'>{t('Figure 4.5')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.2. {t('Endoscopy_title')}</b></h3>
                                <br></br>
                                {t('Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_gast_case')}></img>
                                <div className='screen_string'>{t('Figure 4.7')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.3. {t('Path_title')}</b></h3>
                                <br></br>
                                {t('Pathological Anatomy Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_pat_menu')}></img>
                                <div className='screen_string'>{t('Figure 4.8')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_pat_case')}></img>
                                <div className='screen_string'>{t('Figure 4.9')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.4. {t('Bio_title')} </b></h3>
                                <br></br>
                                {t('Parasitilogy info')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_par_menu')}></img>
                                <div className='screen_string'>{t('Figure 5')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.5. {t('Medical_entomology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_arch_case')}></img>
                                <div className='screen_string'>{t('Figure 5.2')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.6. {t('Helminthology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_his_case')}></img>
                                <div className='screen_string'>{t('Figure 5.3')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.7. {t('Protozoology')}</b></h3>
                                <br></br>
                                <img className="screen_menu" src={t('screens_par_case')}></img >
                                <div className='screen_string'>{t('Figure 5.4')}</div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <h3><b>4.8. {t('Histology_title')}</b></h3>
                                <br></br>
                                {t('Histology terms')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_histo_case')} ></img >
                                <div className='screen_string'>{t('Figure 5.5')}</div>
                                <br></br>
                                <br></br>
                                <h3><b>4.9. {t('Clinical_lab_diagnistics')}</b></h3>
                                <br></br>
                                <br></br>
                                {t('KLD Contains photographs')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_kld_menu')} ></img >
                                <div className='screen_string'>{t('Figure 5.6')}</div>
                                <br></br>
                                <h3><b>5. {t('Maps_title')}</b></h3>
                                <div className='terms_main_desc'>
        {t('epidemic_maps.main_window.description')}
        <br /><br />
        <img className="screen_menu" src={t('Общий вид')} alt={t('Maps_title')}/>
        <div className='screen_string'>{t('Рисунок 5.1: Общий вид интерфейса')}</div>
                                    <br /><br />

        {t('epidemic_maps.frame_panel.description')}
        <br/><br/>
        <img className="screen_menu_map" src={t('Ползунок Выбор кадра')} alt="Панель управления"/>
                                    <div className='screen_string'>{t('Рисунок 5.2: Панель управления временем')}</div>
                                    <br /><br />


        {t('epidemic_maps.info_window.description')}  <br /><br />

        <div className='image-text-container'>
            <div className='text-content'>

                {t('epidemic_maps.model_window.description')}
            </div>
            <img className="screen_menu_map" src={t('Окно Выбор модели')} alt="Окно модели"/>
        </div>
        <div className='screen_string'>{t('Рисунок 5.3: Окно выбора модели')}</div>
        <br /><br />
        <div className='image-text-container'>
            <div className='text-content'>

                {t('epidemic_maps.layers_window.description')}
            </div>
            <img className="screen_menu_map" src={t('Окно Слой')}/>
        </div>
        <div className='screen_string'>{t('Рисунок 5.4: Управление слоями')}</div>
        <br /><br />

        {t('epidemic_maps.map_zoom.description')}


        {t('epidemic_maps.heatmap_graph.description')}
        <br/><br/>
        <img className="screen_menu_map" src={t('График динамики')} alt="График динамики"/>
        <div className='screen_string'>{t('Рисунок 5.5: Визуализация данных')}</div>
        <br /><br />

        {t('epidemic_maps.heatmap_colors.description')}
        <br /><br />

        {t('epidemic_maps.graph_lines.description')}
        <br /><br />

        <div className='step-container'>{t('epidemic_maps.steps_calculate.title')} <br /><br />
                                        <div className='step-text'>{t('epidemic_maps.steps_calculate.step1')}</div>
                                        <br /><br />
            <img src={t('Кнопка Выбор модели')} alt="Кнопка выбора модели"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_calculate.step2')}</div>
            <div className='image-group'>
                                            <img src={t('Поле Новый расчет')} alt="Поле ввода" />
                                            <br /><br />
                <img src={t('Поле Новый расчет со списком')} alt="Выпадающий список"/>
            </div>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_calculate.step3')}</div>
                                        <br /><br />
            <img src={t('Поле Инфекция')} alt="Выбор инфекции"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_calculate.step4')}</div> <br /><br />
            <div className='image-group'>
                                            <img src={t('Поле Город')} alt="Поле города" />
                                            <br /><br />
                <img src={t('Карта Выбор города')} alt="Выбор на карте"/>
            </div>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_calculate.step5')}</div>
                                        <br /><br />
            <img src={t('Маркер первичного очага эпидемии')} alt="Маркер очага"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_calculate.step6')}</div>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_calculate.step7')}</div>
                                        <br /><br />
            <img src={t('Кнопка Рассчитать')} alt="Кнопка расчета"/>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_calculate.step8')}</div>
                                        <br /><br />
            <img src={t('Кнопка Расчет')} alt="Прогресс расчета"/>
        </div>
        <br /><br />
        <h2>{t('epidemic_maps.steps_load.title')}</h2>
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_load.step1')}</div>
                                        <br /><br />
            <img src={t('Поле Выбор расчета')} alt="Выбор расчета"/>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_load.step2')}</div>
                                        <br /><br />
            <img src={t('Кнопка Загрузить')} alt="Кнопка загрузки"/>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_load.step3')}</div>
                                        <br /><br />
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_load.step4')}</div>
                                        <br /><br />
            <img src={t('Ползунок Выбор кадра')} alt="Управление временем"/>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_load.step5')}</div>
                                        <br /><br />
            <img src={t('Кнопка Слой')} alt="Управление слоями"/>
        </div>
        <br /><br />
        <h2>{t('epidemic_maps.steps_rename.title')}</h2>
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_rename.step1')}</div>
                                        <br /><br />
            <img src={t('Поле Выбор расчета')} alt="Выбор расчета"/>
        </div>
        <br /><br />
        <div className='step-container'>
                                        <div className='step-text'>{t('epidemic_maps.steps_rename.step2')}</div>
                                        <br /><br />
            <img src={t('Кнопка Переменовать расчет')} alt="Кнопка переименования"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_rename.step3')}</div>
            <img src={t('Поле Новое имя расчета')} alt="Поле имени"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_rename.step4')}</div>
            <img src={t('Кнопка Подтвердить переименование')} alt="Подтверждение"/>
        </div>
        <br /><br />
        <h2>{t('epidemic_maps.steps_delete.title')}</h2>
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_delete.step1')}</div>
            <img src={t('Поле Выбор расчета')} alt="Выбор расчета"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_delete.step2')}</div>
            <img src={t('Кнопка Удалить расчет')} alt="Кнопка удаления"/>
        </div>
        <br /><br />
        <div className='step-container'>
            <div className='step-text'>{t('epidemic_maps.steps_delete.step3')}</div>
            <img src={t('Кнопка Подтвердить удаление')} alt="Подтверждение удаления"/>
        </div>
    </div>
                            </div >
                        </div >
                        <div className='terms_main_block_selected' id="Демо-версия">
                            <div className='terms_main_name_title'>5. {t('Demo version')}</div>
                            <div className='terms_main_desc'>
                                {t('All users are by default granted access only to the demo version of the application.')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_demo_case')}></img>
                                <div className='screen_string'>{t('Figure 5.7')}</div>
                            </div>
                        </div >
                        <div className='terms_main_block_selected' id="Мобильная версия">
                            <div className='terms_main_name_title'>6. {t('Mobile version')}</div>
                            <div className='terms_main_desc'>{t('The web application also has a mobile adaptive version')}
                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Система поиска в категориях">
                            <div className='terms_main_name_title'>7. {t('Search system')}</div>
                            <div className='terms_main_desc'>
                                {t('The application has a built-in simple search')}
                            </div>
                        </div>
                        <div className='terms_main_block_selected' id="Настройка аккаунта">
                            <div className='terms_main_name_title'>8. {t('Account settings')}</div>
                            <div className='terms_main_desc'>
                                {t('The application has user account settings')}
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_set_user')}></img>
                                <div className='screen_string'>{t('Figure 5.8')}</div>
                                <br></br>
                                <br></br>
                                <img className="screen_menu" src={t('screens_set_user_2')}></img>
                                <div className='screen_string'>{t('Figure 5.9')}</div>
                            </div >
                        </div >
                        <div className='terms_main_block_selected' id="Приобретение подписки">
                            <div className='terms_main_name_title'>9. {t('Subscription')}</div>
                            <div className='terms_main_desc'>
                                {t('By default, the application is available in demo mode')}                                <br></br>
                                <br></br>
                                {t('Subscription is provided by upgrading')} <b><a href="mailto:sale@pimunn.net"> sale@pimunn.net</a></b>, {t('or mobile phone')} <b>+7(920)002-11-55</b>
                                <br></br>
                                <br></br>
                                {t('subscribs allow')}
                                <br></br>
                                <br></br>
                                {t('subscription names')}
                                <br></br>
                                <br></br>
                                {t('university')}
                                <br></br>
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 900.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.900.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 5.000.000 ₽
                                <br></br>
                                <br></br>
                                {t('college')}
                                <br></br>
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 630.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.330.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 3.250.000 ₽
                            </div></div>
                        <div className='terms_main_block_selected' id="Техническая поддержка пользователей">
                            <div className='terms_main_name_title'>10. {t('Technical support for users')}</div>
                            <div className='terms_main_desc'>{t('quick_support')}</div>
                            <img className="screen_menu" src={t('screens_support_page')}></img>
                            <div className='screen_string'>{t('Figure 5.10')}</div>
                            <br></br>
                            <div className='terms_main_desc'>
                                {t('support_mails')}<a href="mailto:support_atlas@pimunn.net">
                                 support_atlas@pimunn.net
                                </a></div>
                            <div className='terms_main_desc'>{t('greetings support')}</div>

                        </div>
                        <div className='terms_main_block_selected' id="Заключение">
                            <div className='terms_main_name_title'>11. {t('Conclusion')}</div>

                        </div>
                    </div >
                </div >
            </div >
        </div >
    </>;
});

export default Terms_Page;
