/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_Images_Display.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "эндоскопия". В этом файле находится компонент, отвечающий за вывод всех картинок в кейсе.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React from 'react';
import Endoscopy_Data_Image from "./Endoscopy_Data_Image";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "./Endoscopy_Data_Images_Display.css"


const Endoscopy_Data_Images_Display = ({ imageurl }) => {
    const imageurldef = "/";

    const handleContextMenu = (e) => {
        e.preventDefault(); // Отключаем контекстное меню
    };

    return (
        <div className="case_images" onContextMenu={handleContextMenu} key={"image_name"} >
            {imageurl.map((url, i) => (
                <>
                    <Zoom key={i}>
                        <Endoscopy_Data_Image url={imageurldef + url} />
                    </Zoom>
                </>
            ))}
        </div>
    );
};

export default Endoscopy_Data_Images_Display;