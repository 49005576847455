import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../pages/News_Page.css';


const Pagination = ({ totalPages, currentPage, paginate }) => (
    <div className="pagination">
        <AnimatePresence mode='wait'>
            {Array.from({ length: totalPages }, (_, index) => (
                <motion.button
                    key={index + 1}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                    className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => paginate(index + 1)}
                >
                    {index + 1}
                </motion.button>
            ))}
        </AnimatePresence>
    </div>
);

export default Pagination;