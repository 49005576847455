/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AccountPage.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к страницам сайта "мой профиль". Страница профиля пользователя отвечает за логику отображения и обновления информации о пользователе.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate, useResolvedPath } from 'react-router-dom';

import { check, passwordUpdate, profileUpdate } from '../../http/User_API';
import { fetchTesting_UserData } from '../../http/Data_API/Testing_Data_API';

import universities from '../../utils/university.json';
import specialties from '../../utils/specialty.json';

import MyUsersUniversity from './MyUsersUniversity';
import MyUsersSchool from './MyUsersSchool';

import './AccountPage.css';

const ProfileInformationBlock = ({ t, user }) => {
    if (!user)
        return;

    const userUniversity = user.profile?.profile?.university ? universities.find( ({ value }) => value == user.profile?.profile?.university )?.label ?? user.profile.profile.university : null;
    const userSpecialty = user.profile?.profile?.specialty ? specialties.find( ({ value }) => value == user.profile?.profile?.specialty )?.label ?? user.profile.profile.specialty : null;

    return <div className='profile-info'>
        <div className='heading'>
            { user.profile.profile.firstName && user.profile.profile.lastName ?
                <>{user.profile.profile.firstName}&nbsp;{user.profile.profile.lastName}</> :
                <>{t('profile_info_login')}&nbsp;&laquo;{user.profile.email}&raquo;</>
            }
        </div>
        {user.profile.email && user.profile.email.indexOf('@') != -1 ? <p>
            <span className='text-body-secondary'>{t('profile_info_email')}:</span>&nbsp;{user.profile.email}
        </p> : <></> }
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_edit_name')}:
            </div>
            <div className='col-auto'>
                {user.profile.profile?.firstName?.length ? user.profile.profile?.firstName : t('profile_info_entry_unspecified')}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_edit_surname')}:
            </div>
            <div className='col-auto'>
                {user.profile.profile?.lastName?.length ? user.profile.profile?.lastName : t('profile_info_entry_unspecified')}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                    {t('profile_info_register_date')}:
            </div>
            <div className='col-auto'>
                {user.profile?.createdAt instanceof Date ? user.profile.createdAt.toLocaleString(user.language) : new Date(user.profile?.createdAt).toLocaleString(user.language)}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_info_role')}:
            </div>
            <div className='col-auto'>
                {t(user.profile?.roleInfo?.title)}
            </div>
        </div>
        {user.profile.role === 'school' && <div className='group school'>
            <p className='heading'>{t('profile_info_school')}</p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_school')}:</span>&nbsp;
                {user.profile?.profile?.university ?? t('profile_info_entry_unspecified')}
            </p>
        </div>}
        {(user.profile.role === 'university' || user.profile.role === 'student' || user.profile.role === 'teacher') &&<div className='group university'>
            <p className='heading'>
                {t('profile_info_university')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_university')}:</span>&nbsp;{userUniversity ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_edit_faculty')}:</span>&nbsp;{user.profile.profile?.faculty ?? t('profile_info_entry_unspecified')}
            </p>
            {user.profile.role === 'teacher' &&
            <p>
                <span className='text-body-secondary'>{t('profile_info_specialty')}:</span>&nbsp;{userSpecialty ?? t('profile_info_entry_unspecified')}
            </p> }
            {user.profile.role === 'teacher' &&
            <p>
                <span className='text-body-secondary'>{t('profile_info_position')}:</span>&nbsp;{user.profile?.profile?.position ?? t('profile_info_entry_unspecified')}
            </p> }
        </div>}
        {user.profile?.profile?.moodle && <div className='group moodle'>
            <p className='heading'>
                {t('profile_info_moodle')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_client')}:</span>&nbsp;{user.profile?.profile?.moodle?.clientdescription ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_login')}:</span>&nbsp;{user.profile?.profile?.moodle?.username ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_country')}:</span>&nbsp;{user.profile?.profile?.moodle?.country ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_lang')}:</span>&nbsp;{user.profile?.profile?.moodle?.language ?? t('profile_info_entry_unspecified')}
            </p>
        </div>}
    </div>;
};

const DemoAccessDescription = ({ t, user, location }) => {
    return <>
        <p className='fs-5 text-body-secondary'>
            {t('profile_subscription_demo_description')}
        </p>
        <div className='row row-cols-1 row-cols-md-2 mb-3 text-center'>
            <div className='col'>
                <div className='card mb-4 rounded-3 shadow-sm'>
                    <div className='card-header py-3'>
                        <h4 className='my-0 fw-normal'>{t('profile_subscription_demo_version')}</h4>
                    </div>
                    <div className='card-body'>
                        <h1 className='card-title pricing-card-title'>
                            {t('profile_subscription_free')}
                        </h1>
                        <ul className='list-unstyled mt-3 mb-4'>
                            <li>{t('profile_subscription_comparison_all_categories')}</li>
                            <li>{t('profile_subscription_some_cases')}</li>
                            <li>&nbsp;</li>
                        </ul>
                        <button className='w-100 btn btn-lg btn-outline-secondary disabled' type='button'>{t('profile_subscription_current')}</button>
                    </div>
                </div>
            </div>
            <div className='col'>
                <div className='card mb-4 rounded-3 shadow-sm border-primary'>
                    <div className='card-header py-3 text-bg-primary border-primary'>
                        <h4 className='my-0 fw-normal'>{t('profile_subscription_full_version')}</h4>
                    </div>
                    <div className='card-body'>
                        <h1 className='card-title pricing-card-title'>
                            1₽
                            <small className='text-body-secondary fw-light'>/{t('profile_subscription_pricing_year')}</small>
                        </h1>
                        <ul className='list-unstyled mt-3 mb-4'>
                            <li>{t('profile_subscription_comparison_all_categories')}</li>
                            <li>{t('profile_subscription_comparison_all_cases')}</li>
                            <li>{t('profile_subscription_comparison_extravaganza')}</li>
                        </ul>
                        <button onClick={ () => location('/demo_version') } className='w-100 btn btn-lg btn-primary' type='button'>{t('profile_subscription_request_full')}</button>
                    </div>
                </div>
            </div>
        </div>

        <h2 className='display-6 text-center mb-4'>{t('profile_subscription_comparison_title')}</h2>
        <div className='table-responsive'>
            <table className='table text-center'>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t('profile_subscription_demo_title')}</th>
                        <th>{t('profile_subscription_full_title')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_all_categories')}</th>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_all_cases')}</th>
                        <td></td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_extravaganza')}</th>
                        <td></td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
};

const FullAccessDescription = ({ t, user }) => {

    let accessLevelHasNoPagesAllowed = user.access_level.pages_allowed.length === 0;
    let accessLevelHasNoPagesDenied = user.access_level.pages_denied.length === 0;

    const pagesAllowed = user.access_level.pages_allowed.map( p => t(p.replace('/pimu/', '') + '_title') );
    const pagesDenied = user.access_level.pages_denied.map( p => t(p.replace('/pimu/', '') + '_title') );

    return <div className='profile_page-full_access_info'>
        <div>
            {t('profile_subscription_full_description_1')}&nbsp;
            <span className='full_access_info-text-red'>{t('start_page_title')}</span>
        </div>
        <div>
            {t('profile_subscription_full_description_2')}&nbsp;<strong>&laquo;{user.access_level.name}&raquo;</strong>
        </div>
        <br />
        <div>
            {(accessLevelHasNoPagesAllowed && accessLevelHasNoPagesDenied) && <>
                <ul>
                    <li>{t('profile_subscription_comparison_all_categories')}</li>
                    <li>{t('profile_subscription_comparison_all_cases')}</li>
                </ul>
            </>}
            {(accessLevelHasNoPagesAllowed && !accessLevelHasNoPagesDenied) && <>
                {t('profile_subscription_comparison_partial_access')}<br />
                {t('profile_subscription_no_access')}:&nbsp;
                <ul>
                    {pagesDenied.map( p => <li>{p}</li> )}
                </ul>
            </>}
        </div>
    </div>;
};

//  Информация об уровне доступа текущего пользователя.
//  Если пользователь демо - выводится блок с предложением покупки полной версии,
//  если пользователь не демо - то помимо информации о том, что приобретён полный доступ,
//  необходимо вывести к каким категориям есть доступ.
const ProfileSubscriptionBlock = ({ t, user, location }) => {
    if (!user)
        return;

    const isDemo = user.access_level.demo_access;

    return <div>
        <p className='heading'>{t('profile_subscription_title')}</p>
        { isDemo ? <DemoAccessDescription t={t} user={user} location={location} /> : <FullAccessDescription t={t} user={user} /> }
    </div>;
};

const PasswordUpdateBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    //  Обработка обновления пароля.
    const UpdatePassword = async (event) => {
        event.preventDefault();

        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        setError({ status: null, message: null, where: null });

        try
        {
            if (!requestData.current || !requestData.new)
                throw new Error('profile_edit_error_no_password');

            if (requestData.current == requestData.new)
                throw new Error('profile_edit_error_same_password');

            const result = await passwordUpdate({ passwordCurrent: requestData.current, passwordNew: requestData.new });

            setError({ status: result.result, message: result.message, where: 'password' });
        }catch (error)
        {
            setError({ status: false, message: error.message, where: 'password' });
        }
    };

    return <div>
        <form onSubmit={UpdatePassword}>
            <div className='form-floating mb-3'>
                <h4>{t('profile_edit_password_update_title')}</h4>
            </div>
            <div className='form-floating mb-3'>
                <input type='password' name='current' className='form-control' required />
                <label>{t('profile_edit_password_current')}</label>
            </div>
            <div className='form-floating mb-3'>
                <input type='password' name='new' className='form-control' required />
                <label>{t('profile_edit_password_new')}</label>
            </div>
            <div className='form-floating mb-3'>
                {error.message && error.where == 'password' && <div className={ `fs-6 alert alert-${error.status ? 'success' : 'danger'}` }>{t(error.message)}</div>}
            </div>
            <div className='form-floating'>
                <button className='btn btn-outline-primary' type='submit'>{t('profile_edit_update')}</button>
            </div>
        </form>
    </div>;
};

const ProfileUpdateBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    //  Обработка обновления информации профиля.
    const UpdateProfile = async (event) => {
        event.preventDefault();

        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        setError({ status: null, message: null, where: null });

        try
        {
            const result = await profileUpdate({ profile: requestData });

            setError({ status: result.result, message: result.message, where: 'profile' });
        }catch (error)
        {
            setError({ status: false, message: error.message, where: 'profile' });
        }
    };

    return <div>
        <form onSubmit={UpdateProfile}>
            <div className='form-floating mb-3'>
                <h4>{t('profile_edit_bio_title')}</h4>
            </div>
            <div className='form-floating mb-3'>
                <input type='text' name='firstName' className='form-control' defaultValue={user.profile.profile?.firstName} required />
                <label>{t('profile_edit_name')}</label>
            </div>
            <div className='form-floating mb-3'>
                <input type='text' name='lastName' className='form-control' defaultValue={user.profile.profile?.lastName} required />
                <label>{t('profile_edit_surname')}</label>
            </div>
            {user.profile.role == 'university' && <div className='form-floating mb-3'>
                <select className='form-select' name='university' defaultValue={user.profile.profile?.university} required>
                    {universities.map( (u) => <option key={u.value} value={u.value}>{u.label}</option>)}
                </select>
                <label>{t('profile_edit_university')}</label>
            </div>}
            {user.profile.role == 'student' && <div className='form-floating mb-3'>
                <input className='form-control' name='faculty' defaultValue={user.profile?.profile?.faculty} type='text' />
                <label>{t('profile_edit_faculty')}</label>
            </div>}
            <div className='form-floating mb-3'>
                {error.message && error.where == 'profile' && <div className={ `fs-6 alert alert-${error.status ? 'success' : 'danger'}` }>{t(error.message)}</div>}
            </div>
            <div className='form-floating'>
                <button className='btn btn-outline-primary' type='submit'>{t('profile_edit_save')}</button>
            </div>
        </form>
    </div>;
};

const ProfileEditBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    return <div>
        {
            //<PasswordUpdateBlock t={t} user={user} error={error} setError={setError} />
        }
        <ProfileUpdateBlock t={t} user={user} error={error} setError={setError} />
    </div>;
};

const ProfilePassedQuizBlock = ({ t, user }) => {
    const [ quiz, setQuiz ] = useState([]);

    useEffect( () => {
        fetchTesting_UserData(user.profile.id).then( t => {
            setQuiz(t);
        });
    }, [] );

    const QuizQuestionResult = ({ row, qid, answers }) => {
        return <div className='row' key={row.id}>
            <div className='title'>{row['question_' + user.language]}</div>
            <div className='answers'>
                <ul>
                    {row['answer_' + user.language].split('|').map( (a, i) => <li key={i}>{a}&nbsp;{row.true_answer.split('|').map(Number).includes(i + 1) ? t('quiz_correct_short') : t('quiz_incorrect_short')}&nbsp;{answers[qid]?.includes(i + 1) && t('quiz_chosen_short')}</li> )}
                </ul>
            </div>
        </div>;
    };

    const ResultMark = (q) => {
        if (!q)
            return;

        
        
        const percentCorrect = ((q.correct / q.quizid.length) * 100);
        
        let resultMark = 0;

        //  Оценка, на основании процента правильных ответов.
        if (percentCorrect <= 70)
            resultMark = 2;

        if (percentCorrect > 70 && percentCorrect <= 79)
            resultMark = 3;

        if (percentCorrect > 80 && percentCorrect <= 89)
            resultMark = 4;

        if (percentCorrect >= 90)
            resultMark = 5;

        return `${resultMark} (${percentCorrect}%)`;
    };

    return <div className='testing_results'>
        {quiz.length == 0 && <div>
                <div className='heading'>
                    {t('profile_quiz_noquiz')}
                </div>
                {t('profile_quiz_noquiz_hint')}
        </div>}

        {quiz.map( (q) => <div key={q.id} className='entry'>
            <div className='head'>
                <div className='title_and_date'>
                    <div className='title'>
                        {t('quiz_type_' + q.mode)}&nbsp;&ndash;&nbsp;{q.category['name_' + user.language]}
                    </div>
                    <div className='date'>
                        {new Date(q.createdAt).toLocaleString(user.language == 'ru' ? 'ru-RU' : 'en-US')}
                    </div>
                </div>
            </div>
            <div className='data'>
                {t('quiz_correct_short')}&nbsp;{q.correct}/{q.quizid.length}
                <br />
                {t('quiz_mark')}:&nbsp;{ResultMark(q)}
            </div>
        </div>)}
    </div>;
};

const AccountPage = observer( () => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const [ activeBlock, setActiveBlock ] = useState('information');
    const [ error, setError ] = useState({ result: null, message: null, where: null });
    const location = useNavigate();

    const PossibleProfileBlockId = [
        'information',
        'edit',
        'subscription',
        'myusers',
        'quizzes'
    ];

    const ToggleProfileContent = async (event) => {
        const targetId = event.target.dataset?.targetId;
        if (!targetId)
            return;

        const data = await check();
        user.setProfile(data.user);
        setError({ result: null, message: null, where: null });
        setActiveBlock(targetId);

        if (!window.location.hash)
            window.location.hash = targetId;
        else
            if (window.location.hash != targetId)
                window.location.hash = targetId;
    };

    if (!user.profile?.profile)
    {
        location('/');
        return;
    }

    //  Если в пути было передано желаемое ID блока профиля, то переключиться туда.
    useEffect( () => {
        const targetHash = window.location?.hash ? window.location.hash.substring(1) : null;

        if (targetHash && PossibleProfileBlockId.includes(targetHash))
            ToggleProfileContent({ target: { dataset: { targetId: targetHash } } });
    }, []);

    const roleHasAccessTo = user.profile?.roleInfo?.access_allowed ?? [];
    const userRoleId = user?.profile?.role ?? null;

    return <div className='profile'>
        <div className='header'>
            <img src='/icons/grey_logo_pimu.png' width={100} height={102} alt='Profile header' />
            <span className='title'>{t('profile_title')}</span>
            <div className='small'>{t('profile_title_description')}</div>
        </div>
        <div className='content'>
            <div className='menu'>
                <button data-target-id='information' className={activeBlock == 'information' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_info')}</button>
                {!user.profile?.profile.moodle && <button data-target-id='edit' className={activeBlock == 'edit' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_edit')}</button> }
                <button data-target-id='subscription' className={activeBlock == 'subscription' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_subscription')}</button>
                {roleHasAccessTo.includes('control_my_users') && <button data-target-id='myusers' className={activeBlock == 'myusers' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_myusers')}</button>}
                <button data-target-id='quizzes' className={activeBlock == 'quizzes' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_quiz')}</button>
            </div>
            <div className='block'>
                { activeBlock === 'information' && <ProfileInformationBlock t={t} user={user} /> }
                { activeBlock === 'edit' && <ProfileEditBlock t={t} user={user} error={error} setError={setError} /> }
                { activeBlock === 'subscription' && <ProfileSubscriptionBlock t={t} user={user} location={location} /> }
                { activeBlock === 'myusers' && <>
                {userRoleId === 'university' ? <MyUsersUniversity user={user} error={error} setError={setError} /> : <></>}
                {userRoleId === 'school' ? <MyUsersSchool user={user} /> : <></>}
                </> }
                { activeBlock === 'quizzes' && <ProfilePassedQuizBlock t={t} user={user} error={error} setError={setError} /> }
            </div>
        </div>
    </div>;
});

export default AccountPage;