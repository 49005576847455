/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Data_EntriesList.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "Аускультация". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Горбас Александр Петрович, Белов Михаил Александрович
*/
import { observer } from "mobx-react-lite";
import Auscultation_Data_EntriesItem from "./Auscultation_Data_EntriesItem";

const Auscultation_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='dzi_items_flex auscultation'>
        {pageData.items.rows.map((item) => <Auscultation_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Auscultation_Data_EntriesList;