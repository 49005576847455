/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "Аускультация". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Горбас Александр Петрович, Белов Михаил Александрович,
 */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Auscultation_EntriesPerPage } from './Auscultation_Categories_Page';
import './Auscultation_Data_Entrie_Page.css';

const Auscultation_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const audioRef = useRef(null);
    const seekSliderRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [isLooping, setIsLooping] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isAudioReady, setIsAudioReady] = useState(false);

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Auscultation_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
            setCurrentTime(0);
            setIsAudioReady(false);
        }
    }, [pageData]);

    useEffect(() => {
        if (pageData.item?.audio_path && audioRef.current) {
            audioRef.current.load();
        }
    }, [pageData.item?.audio_path]);

    useEffect(() => {
        if (seekSliderRef.current) {
            seekSliderRef.current.value = currentTime;
        }
    }, [currentTime]);

    const togglePlayPause = async () => {
        if (!isAudioReady) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            try {
                await audioRef.current.play();
            } catch (error) {
                console.error('Ошибка воспроизведения:', error);
            }
        }
        setIsPlaying(!isPlaying);
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    const toggleMute = () => {
        const newMutedState = !isMuted;
        setIsMuted(newMutedState);
        audioRef.current.muted = newMutedState;
    };

    const toggleLoop = () => {
        const newLoopState = !isLooping;
        setIsLooping(newLoopState);
        audioRef.current.loop = newLoopState;
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
        setIsAudioReady(true);
    };

    const handleSeek = (e) => {
        audioRef.current.currentTime = e.target.value;
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
        setCurrentTime(0);
        if (seekSliderRef.current) {
            seekSliderRef.current.value = 0; 
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    if (!pageData || !pageData?.category || !pageData?.item)
        return null;

    return (
        <>
            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/auscultation_icon.png' alt='page_title_icon' />
                {t('auscultation_title')}
            </div>
            <div className="flex_container">
                <div className="flex_container_title">
                    <div className="item flex_item_1">
                        {pageData.category['name_' + user.language]}
                    </div>
                    <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
                </div>
                <hr />
                <div className="case_birth">
                    <div className='case_flex_onerow'>
                        <div className="case_bold_name">
                            {pageData.item['name_' + user.language]}
                        </div>
                    </div>
                </div>
                <div className='case_text_info'>{t('description')}</div>
                <div className="case_text_block mb-4">
                    {pageData.item['description_' + user.language]}
                </div>
                <div className='case_text_info'>{t('audio')} <img src='/audio/earmuffs.png' alt="Audio" /></div>
                {pageData.item.audio_path && (
                    <div className="audio_block">
                        <audio
                            ref={audioRef}
                            onTimeUpdate={handleTimeUpdate}
                            onLoadedMetadata={handleLoadedMetadata}
                            onCanPlay={() => setIsAudioReady(true)}
                            onEnded={handleAudioEnd}
                        >
                            <source src={pageData.item.audio_path} type="audio/mpeg" />
                            Ваш браузер не поддерживает воспроизведение аудио.
                        </audio>
                        <div className="audio_controls">
                            <div className="play_repeat_controls">
                                <button
                                    className={`control_button ${isPlaying ? 'pause' : 'play'}`}
                                    onClick={togglePlayPause}
                                    disabled={!isAudioReady}
                                    onTouchEnd={(e) => {
                                        e.preventDefault();
                                        togglePlayPause();
                                    }}
                                >
                                    {isPlaying ? (
                                        <img src='/audio/pause.svg' alt="Pause" />
                                    ) : (
                                        <img src='/audio/play.svg' alt="Play" />
                                    )}
                                </button>
                                <button
                                    className={`control_button repeat ${isLooping ? 'active' : ''}`}
                                    onClick={toggleLoop}
                                    onTouchEnd={(e) => {
                                        e.preventDefault();
                                        toggleLoop();
                                    }}
                                >
                                    <img src='/audio/repeat.svg' alt="Repeat" />
                                </button>
                            </div>
                            <input
                                type="range"
                                className="seek_slider"
                                ref={seekSliderRef}
                                value={currentTime}
                                max={duration}
                                onChange={handleSeek}
                            />
                            <span className="time_display">
                                {formatTime(currentTime)} / {formatTime(duration)}
                            </span>
                            <div className="volume_controls">
                                <button 
                                    className={`control_button ${isMuted ? 'muted' : ''}`}
                                    onClick={toggleMute}
                                    onTouchEnd={(e) => {
                                        e.preventDefault();
                                        toggleMute();
                                    }}
                                >
                                    {isMuted ? (
                                        <img src='/audio/mute.svg' alt="Mute" />
                                    ) : (
                                        <img src='/audio/volume.svg' alt="Volume" />
                                    )}
                                </button>
                                <input
                                    type="range"
                                    className="volume_slider"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={volume}
                                    onChange={handleVolumeChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <BottomCaseNavigation pageData={pageData} />
            </div>
        </>
    );
};

export default Auscultation_Data_Entrie_Page;