/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Dentistry_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "Стоматология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
 */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import SliderProtected from '../../components/ProtectedImageSlider';
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";
import { observer } from 'mobx-react-lite';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Dentistry_EntriesPerPage } from './Dentistry_Categories_Page';

const Dentistry_Data_Entrie_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const selectedGender = new URLSearchParams(location.search).get('gender');
    const [ descriptionIndex, setDescriptionIndex ] = useState(0);

    useEffect(() => {
        PrepareItemPage(location, user.language, Dentistry_EntriesPerPage).then((data) => {
            setPageData({
                ...data,
                item: {
                    ...data.item,
                    description2: {
                        ru: data.item.description2_ru.split('|'),
                        en: data.item.description2_en.split('|'),
                    },
                },
            });
        });
    }, [location, user]);

    const HandleImageChange = (imageIndex) => {
        setDescriptionIndex(imageIndex);
    };

    if (!pageData || !pageData?.category || !pageData?.item)
        return;

    return <div className='ecg_page_block'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/dentistry_icon.png' alt='page_title_icon'></img>{t('dentistry_title')}
        </div>
        <div className="flex_container" key={pageData.item.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.item['name_' + user.language]}
                </div>
                <TopCaseNavigation className='top_case_navigation_block' pageData={pageData} />
            </div>

            <hr />
            <br></br>

            <div className='case_flex'>
                <div className='case_conclusion'>
                    <div className='case_text_info'>{t('description')}</div>
                    <div className='case_text_block_bold'>
                        {pageData.item['description_' + user.language]}</div>
                </div>
            </div>

            <div className="dzi_section">
                <SliderProtected
                    imageurl={pageData.item.images_path}
                    onSlideChanged={HandleImageChange}
                    imagesPerView={1}
                />
            </div>

            {pageData.item.description2[user.language][descriptionIndex] && <div>
                <div className='case_text_info'>{t('description')}</div>
                <div className='case_text_block'>
                    {pageData.item.description2[user.language][descriptionIndex]}
                </div>
            </div>}
            <br />
            <div className='case_flex'>
                <div className='case_conclusion'>
                    <div className='case_text_info'>{t('conclusion')}</div>
                    <div className='case_text_block_bold'>
                        {pageData.item['conclusion_' + user.language]}</div>
                </div>
            </div>
            <BottomCaseNavigation pageData={pageData} />
        </div>
    </div>;
});

export default Dentistry_Data_Entrie_Page;
