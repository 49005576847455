/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pharmacology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Клиническая фармакология".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Pharmacology ]

//===========
// begin [ Pharmacology_Categories ]

export const fetchPharmacology_Categories = async (search, language) => {
    const {data} = await $host.get('api/pharmacology/pharmacology_categories', { params: { search, language } });
    return data;
}

export const fetchOnePharmacology_Categories = async (id) => {
    const { data } = await $host.get('api/pharmacology/pharmacology_categories/' + id);
    return data;
}

// end [ Pharmacology_Categories ]
//===========

//-----------

//===========
// begin [ Pharmacology_Data ]

export const fetchPharmacology_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/pharmacology/pharmacology_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOnePharmacology_Data = async (id) => {
    const { data } = await $host.get('api/pharmacology/pharmacology_data/' + id);
    return data;
}

// end [ Pharmacology_Data ]
//===========

//// end [ Pharmacology ]
////===========

//-----------