/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ProtectedImageSlider.jsx
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. В этом файле находится компонент, отвечающий за вывод защищённого изображения для кейса.
 *	@author: Белов Михаил Александрович
*/
import React, { useLayoutEffect, useRef, useEffect, useCallback, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import Swiper from 'react-id-swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';

import "./ProtectedImageSlider.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import ImageProtected from './ProtectedImage';

//  Собственный контент, который будет отображаться при развёртывании изображения на всю страницу (зуме).
//  Сейчас он нужен лишь для того, чтобы отключить у картинки контекстное меню, однако, в дальнейшем,
//  сюда можно добавить к какждой картинке пояснение, которое будет отображаться внизу.
const ProtectedZoomContent = ({ buttonUnzoom, modalState, img }) => {
    return <>
        {buttonUnzoom}
        <div onContextMenu={ e => e.preventDefault() }>
            {img}
        </div>
    </>;
};

//  Само защищённое изображение загружается здесь. Оно будет отображено в виде превью, а при разворачивании на весь экран - будет отображено в полном размере.
//  TODO: необходимо использовать canvas, вместо img! С canvas есть проблема - необходимо точно указать его размеры т.к. он не подстраивается самостоятельно.
const ZoomableImage = (url) => {
    
    return <div key={url} className='swiper-slide'>
        <Zoom 
            ZoomContent={ProtectedZoomContent}
        >
            <ImageProtected url={url} className='ekg_picture' />
        </Zoom>   
    </div>;
};

const SliderProtected = ({ imageurl, onSlideChanged, imagesPerView = 2 }) => {

    return (
        <div>
            <Swiper
                modules={[Navigation, Pagination, Keyboard]}
                spaceBetween={10}
                slidesPerView={imagesPerView}
                navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next"
                }}
                pagination={{
                    el: ".swiper-pagination",
                    clickable: true
                }}
                on={{
                    slideChange: (swiper) => onSlideChanged instanceof Function ? onSlideChanged(swiper.activeIndex) : null,
                }}
                keyboard={true}
            >
                {imageurl.map(ZoomableImage)}
            </Swiper>
        </div>
    );
};

export default SliderProtected;
