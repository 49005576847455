/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: routes.js
 *  @description: Этот файл является частью клиентской стороны проекта. В этом файле находится описание имеющихся путей в клиентской части приложения и назначенных для этих путей компонентов, занимающихся отображением данных, с разделением по публичным и непубличным путям.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import {
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    REGISTRATION_ROUTE,
    ACCOUNT_ROUTE,
    PASSWORD_RESTORE_ROUTE,
    START_ROUTE,

    ABOUT_APPLICATION_ROUTE,
    CONTACTS_ROUTE,
    SUPPORT_ROUTE,
    SUPPORT_GUIDE_ROUTE,
    DEMO_VERSION_ROUTE,

    MAP_ROUTE,

    PIMU_CATEGORIES_ROUTE,

    ECG_DATA_ENTRIE, ECG_CATEGORIES_ROUTE,

    TestExtra_DATA_ENTRIE, TestExtra_CATEGORIES_ROUTE,

    Biology_DATA_ENTRIE, Biology_CATEGORIES_ROUTE,

    Patanatomy_DATA_ENTRIE, Patanatomy_CATEGORIES_ROUTE,

    Gistology_DATA_ENTRIE, Gistology_CATEGORIES_ROUTE,

    Endoscopy_DATA_ENTRIE, Endoscopy_CATEGORIES_ROUTE,

    KLD_DATA_ENTRIE, KLD_CATEGORIES_ROUTE,

    Microbiology_CATEGORIES_ROUTE, Microbiology_DATA_ENTRIE,

    SkinDisease_CATEGORIES_ROUTE, SkinDisease_DATA_ENTRIE,

    Modelling_CATEGORIES_ROUTE, Modelling_DATA_ENTRIE,

    RadiologyDiag_CATEGORIES_ROUTE, RadiologyDiag_DATA_ENTRIE,

    Testing_PAGE_ROUTE,
    Terms_PAGE_ROUTE,
    Subscribe_PAGE_ROUTE,
    System_Requirements_ROUTE,
    // Epidemics_Map_ROUTE,
    Agreement_ROUTE,
	News_ROUTE,

    Botany_CATEGORIES_ROUTE, Botany_DATA_ENTRIE,

    Ultrasound_CATEGORIES_ROUTE, Ultrasound_DATA_ENTRIE,

    Genetics_CATEGORIES_ROUTE, Genetics_DATA_ENTRIE,

    Ophthalmology_CATEGORIES_ROUTE, Ophthalmology_DATA_ENTRIE,

    Spo_CATEGORIES_ROUTE, Spo_DATA_ENTRIE,

    Pharmacology_CATEGORIES_ROUTE, Pharmacology_DATA_ENTRIE,

    Otolaryngology_CATEGORIES_ROUTE, Otolaryngology_DATA_ENTRIE,

    Auscultation_CATEGORIES_ROUTE, Auscultation_DATA_ENTRIE,

    Dentistry_CATEGORIES_ROUTE, Dentistry_DATA_ENTRIE,

} from "./utils/consts";

import LogOutPage from "./pages/LogOut";
import PasswordRestorePage from './pages/Account/PasswordRestorePage';
import AccountPage from "./pages/Account/AccountPage";
import Authorization from "./pages/Authorization";
import Start_Page from "./pages/Start_Page";
import System_Requirements from './pages/System_Requirements';
import Agreement_Page from "./pages/Agreement_Page";
import News_Page from "./pages/News_Page";

import Pimu_Categories_Page from "./pages/Pimu_Categories_Page";

//-----------

import About_Application_Page from "./pages/About_Application_Page";
import Contacts_Page from "./pages/Contacts_Page";
import Support_Page from "./pages/Support/Support_Page";
import Support_guide from "./pages/Support/Support_guide";
import Demo_Version_Page from "./pages/Demo_Version_Page";
import Terms_Page from "./pages/Terms_Page";
import Subscribe_Page from "./pages/Subscribe_Page";

//-----------

import TestingPage from "./pages/Testing/TestingPage";

//-----------

import Epidemics_Map_Page from "./pages/Epidemics/Epidemics";

//-----------

import ECG_Categories_Page from "./pages/ECG/ECG_Categories_Page";
import ECG_Data_Entrie_Page from "./pages/ECG/ECG_Data_Entrie_Page";
import ECG_Data_EntriesTable_Page from "./pages/ECG/ECG_Data_EntriesTable_Page";

//-----------

import TestExtra_Categories_Page from "./pages/TestExtra/TestExtra_Categories_Page";
import TestExtra_Data_Entrie_Page from "./pages/TestExtra/TestExtra_Data_Entrie_Page";
import TestExtra_Data_EntriesTable_Page from "./pages/TestExtra/TestExtra_Data_EntriesTable_Page";

//-----------

import Biology_Categories_Page from "./pages/Biology/Biology_Categories_Page";
import Biology_Data_Entrie_Page from "./pages/Biology/Biology_Data_Entrie_Page";
import Biology_Data_EntriesTable_Page from "./pages/Biology/Biology_Data_EntriesTable_Page";

//-----------

import Patanatomy_Categories_Page from "./pages/Patanatomy/Patanatomy_Categories_Page";
import Patanatomy_Data_Entrie_Page from "./pages/Patanatomy/Patanatomy_Data_Entrie_Page";
import Patanatomy_Data_EntriesTable_Page from "./pages/Patanatomy/Patanatomy_Data_EntriesTable_Page";

//-----------

import Gistology_Categories_Page from "./pages/Gistology/Gistology_Categories_Page";
import Gistology_Data_Entrie_Page from "./pages/Gistology/Gistology_Data_Entrie_Page";
import Gistology_Data_EntriesTable_Page from "./pages/Gistology/Gistology_Data_EntriesTable_Page";

//-----------

import Endoscopy_Categories_Page from "./pages/Endoscopy/Endoscopy_Categories_Page";
import Endoscopy_Data_Entrie_Page from "./pages/Endoscopy/Endoscopy_Data_Entrie_Page";
import Endoscopy_Data_EntriesTable_Page from "./pages/Endoscopy/Endoscopy_Data_EntriesTable_Page";

//-----------

import KLD_Categories_Page from './pages/KLD/KLD_Categories_Page';
import KLD_Data_Entrie_Page from './pages/KLD/KLD_Data_Entrie_Page';
import KLD_Data_EntriesTable_Page from './pages/KLD/KLD_Data_EntriesTable_Page';

//-----------

import Microbiology_Categories_Page from './pages/Microbiology/Microbiology_Categories_Page';
import Microbiology_Data_EntriesTable_Page from './pages/Microbiology/Microbiology_Data_EntriesTable_Page';
import Microbiology_Data_Entrie_Page from './pages/Microbiology/Microbiology_Data_Entrie_Page';

//-----------

import SkinDisease_Categories_Page from './pages/SkinDisease/SkinDisease_Categories_Page';
import SkinDisease_Data_EntriesTable_Page from './pages/SkinDisease/SkinDisease_Data_EntriesTable_Page';
import SkinDisease_Data_Entrie_Page from './pages/SkinDisease/SkinDisease_Data_Entrie_Page';

//-----------

import Modelling_Categories_Page from "./pages/Modelling/Modelling_Categories_Page";
import Modelling_Data_EntriesTable_Page from './pages/Modelling/Modelling_Data_EntriesTable_Page';
import Modelling_Data_Entrie_Page from './pages/Modelling/Modelling_Data_Entrie_Page';

//-----------

import Radiology_Categories_Page from "./pages/Radiology/Radiology_Categories_Page";
import Radiology_Data_EntriesTable_Page from './pages/Radiology/Radiology_Data_EntriesTable_Page';
import Radiology_Data_Entrie_Page from './pages/Radiology/Radiology_Data_Entrie_Page';
import Radiology_StandardCategories_Page from './pages/Radiology/Radiology_StandardCategories_Page';

//-----------

import Botany_Categories_Page from './pages/Botany/Botany_Categories_Page';
import Botany_Data_EntriesTable_Page from './pages/Botany/Botany_Data_EntriesTable_Page';
import Botany_Data_Entrie_Page from './pages/Botany/Botany_Data_Entrie_Page';

//-----------

import Ultrasound_Categories_Page from "./pages/Ultrasound/Ultrasound_Categories_Page";
import Ultrasound_Data_EntriesTable_Page from './pages/Ultrasound/Ultrasound_Data_EntriesTable_Page';
import Ultrasound_Data_Entrie_Page from './pages/Ultrasound/Ultrasound_Data_Entrie_Page';
import Ultrasound_StandardCategories_Page from './pages/Ultrasound/Ultrasound_StandardCategories_Page';

//-----------

import Genetics_Categories_Page from './pages/Genetics/Genetics_Categories_Page';
import Genetics_Data_EntriesTable_Page from './pages/Genetics/Genetics_Data_EntriesTable_Page';
import Genetics_Data_Entrie_Page from './pages/Genetics/Genetics_Data_Entrie_Page';

//-----------

import Ophthalmology_Categories_Page from './pages/Ophthalmology/Ophthalmology_Categories_Page';
import Ophthalmology_Data_EntriesTable_Page from './pages/Ophthalmology/Ophthalmology_Data_EntriesTable_Page';
import Ophthalmology_Data_Entrie_Page from './pages/Ophthalmology/Ophthalmology_Data_Entrie_Page';

//-----------

import Spo_Categories_Page from './pages/Spo/Spo_Categories_Page';
import Spo_Data_EntriesTable_Page from './pages/Spo/Spo_Data_EntriesTable_Page';
import Spo_Data_Entrie_Page from './pages/Spo/Spo_Data_Entrie_Page';

//-----------

import Otolaryngology_Categories_Page from './pages/Otolaryngology/Otolaryngology_Categories_Page';
import Otolaryngology_Data_EntriesTable_Page from './pages/Otolaryngology/Otolaryngology_Data_EntriesTable_Page';
import Otolaryngology_Data_Entrie_Page from './pages/Otolaryngology/Otolaryngology_Data_Entrie_Page';

//-----------

import Pharmacology_Categories_Page from './pages/Pharmacology/Pharmacology_Categories_Page';
import Pharmacology_Data_EntriesTable_Page from './pages/Pharmacology/Pharmacology_Data_EntriesTable_Page';
import Pharmacology_Data_Entrie_Page from './pages/Pharmacology/Pharmacology_Data_Entrie_Page';

//-----------

import Auscultation_Categories_Page from './pages/Auscultation/Auscultation_Categories_Page';
import Auscultation_Data_EntriesTable_Page from './pages/Auscultation/Auscultation_Data_EntriesTable_Page';
import Auscultation_Data_Entrie_Page from './pages/Auscultation/Auscultation_Data_Entrie_Page';

//-----------

import Dentistry_Categories_Page from './pages/Dentistry/Dentistry_Categories_Page';
import Dentistry_Data_EntriesTable_Page from './pages/Dentistry/Dentistry_Data_EntriesTable_Page';
import Dentistry_Data_Entrie_Page from './pages/Dentistry/Dentistry_Data_Entrie_Page';

//-----------

export const authRoutes = [
    {
        path: ACCOUNT_ROUTE,
        Component: AccountPage,
    },

    //-----------
    {
        path: PIMU_CATEGORIES_ROUTE,
        Component: Pimu_Categories_Page
    },

    //-----------

    {
        path: Testing_PAGE_ROUTE,
        Component: TestingPage
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + ECG_CATEGORIES_ROUTE,
        Component: ECG_Categories_Page
    },
    {
        path: ECG_CATEGORIES_ROUTE + '/:id',
        Component: ECG_Data_EntriesTable_Page
    },
    {
        path: ECG_CATEGORIES_ROUTE + ECG_DATA_ENTRIE + '/:id',
        Component: ECG_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + TestExtra_CATEGORIES_ROUTE,
        Component: TestExtra_Categories_Page
    },
    {
        path: TestExtra_CATEGORIES_ROUTE + '/:id',
        Component: TestExtra_Data_EntriesTable_Page
    },
    {
        path: TestExtra_CATEGORIES_ROUTE + TestExtra_DATA_ENTRIE + '/:id',
        Component: TestExtra_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Biology_CATEGORIES_ROUTE,
        Component: Biology_Categories_Page
    },
    {
        path: Biology_CATEGORIES_ROUTE + '/:id',
        Component: Biology_Data_EntriesTable_Page
    },
    {
        path: Biology_CATEGORIES_ROUTE + Biology_DATA_ENTRIE + '/:id',
        Component: Biology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Patanatomy_CATEGORIES_ROUTE,
        Component: Patanatomy_Categories_Page
    },
    {
        path: Patanatomy_CATEGORIES_ROUTE + '/:id',
        Component: Patanatomy_Data_EntriesTable_Page
    },
    {
        path: Patanatomy_CATEGORIES_ROUTE + Patanatomy_DATA_ENTRIE + '/:id',
        Component: Patanatomy_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Gistology_CATEGORIES_ROUTE,
        Component: Gistology_Categories_Page
    },
    {
        path: Gistology_CATEGORIES_ROUTE + '/:id',
        Component: Gistology_Data_EntriesTable_Page
    },
    {
        path: Gistology_CATEGORIES_ROUTE + Gistology_DATA_ENTRIE + '/:id',
        Component: Gistology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Endoscopy_CATEGORIES_ROUTE,
        Component: Endoscopy_Categories_Page
    },
    {
        path: Endoscopy_CATEGORIES_ROUTE + '/:id',
        Component: Endoscopy_Data_EntriesTable_Page
    },
    {
        path: Endoscopy_CATEGORIES_ROUTE + Endoscopy_DATA_ENTRIE + '/:id',
        Component: Endoscopy_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + KLD_CATEGORIES_ROUTE,
        Component: KLD_Categories_Page
    },
    {
        path: KLD_CATEGORIES_ROUTE + '/:id',
        Component: KLD_Data_EntriesTable_Page
    },
    {
        path: KLD_CATEGORIES_ROUTE + KLD_DATA_ENTRIE + '/:id',
        Component: KLD_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Microbiology_CATEGORIES_ROUTE,
        Component: Microbiology_Categories_Page
    },
    {
        path: Microbiology_CATEGORIES_ROUTE + '/:id',
        Component: Microbiology_Data_EntriesTable_Page
    },
    {
        path: Microbiology_CATEGORIES_ROUTE + Microbiology_DATA_ENTRIE + '/:id',
        Component: Microbiology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + SkinDisease_CATEGORIES_ROUTE,
        Component: SkinDisease_Categories_Page
    },
    {
        path: SkinDisease_CATEGORIES_ROUTE + '/:id',
        Component: SkinDisease_Data_EntriesTable_Page
    },
    {
        path: SkinDisease_CATEGORIES_ROUTE + SkinDisease_DATA_ENTRIE + '/:id',
        Component: SkinDisease_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Modelling_CATEGORIES_ROUTE,
        Component: Modelling_Categories_Page
    },
    {
        path: Modelling_CATEGORIES_ROUTE + '/:id',
        Component: Modelling_Data_EntriesTable_Page
    },
    {
        path: Modelling_CATEGORIES_ROUTE + Modelling_DATA_ENTRIE + '/:id',
        Component: Modelling_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + RadiologyDiag_CATEGORIES_ROUTE,
        Component: Radiology_Categories_Page
    },
    {
        path: PIMU_CATEGORIES_ROUTE + RadiologyDiag_CATEGORIES_ROUTE + '/:id',
        Component: Radiology_StandardCategories_Page,
    },
    {
        path: RadiologyDiag_CATEGORIES_ROUTE + '/:id',
        Component: Radiology_Data_EntriesTable_Page
    },
    {
        path: RadiologyDiag_CATEGORIES_ROUTE + RadiologyDiag_DATA_ENTRIE + '/:id',
        Component: Radiology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Botany_CATEGORIES_ROUTE,
        Component: Botany_Categories_Page
    },
    {
        path: Botany_CATEGORIES_ROUTE + '/:id',
        Component: Botany_Data_EntriesTable_Page
    },
    {
        path: Botany_CATEGORIES_ROUTE + Botany_DATA_ENTRIE + '/:id',
        Component: Botany_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Ultrasound_CATEGORIES_ROUTE,
        Component: Ultrasound_Categories_Page
    },
    {
        path: PIMU_CATEGORIES_ROUTE + Ultrasound_CATEGORIES_ROUTE + '/:id',
        Component: Ultrasound_StandardCategories_Page,
    },
    {
        path: Ultrasound_CATEGORIES_ROUTE + '/:id',
        Component: Ultrasound_Data_EntriesTable_Page
    },
    {
        path: Ultrasound_CATEGORIES_ROUTE + Ultrasound_DATA_ENTRIE + '/:id',
        Component: Ultrasound_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Genetics_CATEGORIES_ROUTE,
        Component: Genetics_Categories_Page,
    },
    {
        path: Genetics_CATEGORIES_ROUTE + '/:id',
        Component: Genetics_Data_EntriesTable_Page,
    },
    {
        path: Genetics_CATEGORIES_ROUTE + Genetics_DATA_ENTRIE + '/:id',
        Component: Genetics_Data_Entrie_Page,
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Ophthalmology_CATEGORIES_ROUTE,
        Component: Ophthalmology_Categories_Page,
    },
    {
        path: Ophthalmology_CATEGORIES_ROUTE + '/:id',
        Component: Ophthalmology_Data_EntriesTable_Page,
    },
    {
        path: Ophthalmology_CATEGORIES_ROUTE + Ophthalmology_DATA_ENTRIE + '/:id',
        Component: Ophthalmology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Spo_CATEGORIES_ROUTE,
        Component: Spo_Categories_Page,
    },
    {
        path: Spo_CATEGORIES_ROUTE + '/:id',
        Component: Spo_Data_EntriesTable_Page,
    },
    {
        path: Spo_CATEGORIES_ROUTE + Spo_DATA_ENTRIE + '/:id',
        Component: Spo_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Pharmacology_CATEGORIES_ROUTE,
        Component: Pharmacology_Categories_Page,
    },
    {
        path: Pharmacology_CATEGORIES_ROUTE + '/:id',
        Component: Pharmacology_Data_EntriesTable_Page,
    },
    {
        path: Pharmacology_CATEGORIES_ROUTE + Pharmacology_DATA_ENTRIE + '/:id',
        Component: Pharmacology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Otolaryngology_CATEGORIES_ROUTE,
        Component: Otolaryngology_Categories_Page,
    },
    {
        path: Otolaryngology_CATEGORIES_ROUTE + '/:id',
        Component: Otolaryngology_Data_EntriesTable_Page,
    },
    {
        path: Otolaryngology_CATEGORIES_ROUTE + Otolaryngology_DATA_ENTRIE + '/:id',
        Component: Otolaryngology_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Auscultation_CATEGORIES_ROUTE,
        Component: Auscultation_Categories_Page,
    },
    {
        path: Auscultation_CATEGORIES_ROUTE + '/:id',
        Component: Auscultation_Data_EntriesTable_Page,
    },
    {
        path: Auscultation_CATEGORIES_ROUTE + Auscultation_DATA_ENTRIE + '/:id',
        Component: Auscultation_Data_Entrie_Page
    },

    //-----------

    {
        path: PIMU_CATEGORIES_ROUTE + Dentistry_CATEGORIES_ROUTE,
        Component: Dentistry_Categories_Page,
    },
    {
        path: Dentistry_CATEGORIES_ROUTE + '/:id',
        Component: Dentistry_Data_EntriesTable_Page,
    },
    {
        path: Dentistry_CATEGORIES_ROUTE + Dentistry_DATA_ENTRIE + '/:id',
        Component: Dentistry_Data_Entrie_Page
    },

    //-----------

    // {
    //     path: PIMU_CATEGORIES_ROUTE + Epidemics_Map_ROUTE,
    //     Component: Epidemics_Map_Page
    // },

    //-----------

    {
        path: Terms_PAGE_ROUTE,
        Component: Terms_Page,
    },

    //-----------

    {
        path: Subscribe_PAGE_ROUTE,
        Component: Subscribe_Page,
    }

    //-----------
]

export const publicRoutes = [
    {
        path: LOGOUT_ROUTE,
        Component: LogOutPage,
    },
    {
        path: PASSWORD_RESTORE_ROUTE,
        Component: PasswordRestorePage,
    },
    {
        path: PASSWORD_RESTORE_ROUTE + '/:token',
        Component: PasswordRestorePage,
    },
    {
        path: LOGIN_ROUTE,
        Component: Authorization
    },
    {
       path: REGISTRATION_ROUTE,
       Component: Authorization
    },
    {
        path: START_ROUTE,
        Component: Start_Page
    },
    {
        path: ABOUT_APPLICATION_ROUTE,
        Component: About_Application_Page
    },
    {
        path: CONTACTS_ROUTE,
        Component: Contacts_Page
    },
    {
        path: SUPPORT_ROUTE,
        Component: Support_Page
    },
    {
        path: SUPPORT_GUIDE_ROUTE,
        Component: Support_guide
    },
    {
        path: DEMO_VERSION_ROUTE,
        Component: Demo_Version_Page
    },
    {
        path: System_Requirements_ROUTE,
        Component: System_Requirements
    },
    {
        path: Agreement_ROUTE,
        Component: Agreement_Page
    },
	{
		path: News_ROUTE,
		Component: News_Page
	}
]