/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Dentistry_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Стоматология".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Dentistry ]

//===========
// begin [ Dentistry_Categories ]

export const fetchDentistry_Categories = async (search, language) => {
    const {data} = await $host.get('api/dentistry/dentistry_categories', { params: { search, language } });
    return data;
}

export const fetchOneDentistry_Categories = async (id) => {
    const { data } = await $host.get('api/dentistry/dentistry_categories/' + id);
    return data;
}

// end [ Dentistry_Categories ]
//===========

//-----------

//===========
// begin [ Dentistry_Data ]

export const fetchDentistry_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/dentistry/dentistry_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOneDentistry_Data = async (id) => {
    const { data } = await $host.get('api/dentistry/dentistry_data/' + id);
    return data;
}

// end [ Dentistry_Data ]
//===========

//// end [ Dentistry ]
////===========

//-----------