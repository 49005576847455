/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Contacts_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница контактов отвечает за отображение страницы контактов.
 *	@author: Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import './Contacts_Page.css';


const Contacts_Page = observer(() => {
    const history = useNavigate();
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 1024);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    function openMenu(evt, selectedName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("contacts_main_block_selected");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("contacts_sidebar_menu");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(selectedName).style.display = "block";
        evt.currentTarget.className += " active";

        setSidebarOpen(false);
    };

    useEffect(() => {
        const fakeEvent = { currentTarget: { className: "" } };
        openMenu(fakeEvent, 'Техническая поддержка');
    }, []);

    return <>
         <div className='contacts_page'>
            <div className='contacts_grid'>
                <div className={`contacts_sidebar ${isMobile || isSidebarOpen ? 'open' : 'hidden'}`}>
                    <div className='contacts_sidebar_block'>                        
                        <div className='contacts_sidebar_menu' onClick={(e) => openMenu(e, 'Техническая поддержка')}>{t('Technical support for users')}</div>
                        <div className='contacts_sidebar_menu' onClick={(e) => openMenu(e, 'Предложения')}>{t('Subscription')}</div>
                        <div className='contacts_sidebar_menu' onClick={(e) => openMenu(e, 'Демо-доступ')}>{t('Demo version')}</div>
                    </div>
                </div>
                <div className='contacts_main'>
                    <div className='contacts_main_title'>Контакты</div>
                    <div className='contacts_main_block'>
                    <div className='contacts_main_block_selected' id="Техническая поддержка">
                        <div className='contacts_main_name_title'><a href="/support">
                        {t('Technical support for users')}
                            </a></div>
                            <div className='contacts_main_desc'>{t('quick_support')}</div>
                                <img className="screen_menu" src={t('screens_support_page')}></img>
                                <div className='screen_string'>{t('Figure 5.10')}</div>
                                <br></br>          
                                <br></br>                      
                                <div className='contacts_main_desc'>{t('support_mails')}
                                     <a href="mailto:support_atlas@pimunn.net"> support_atlas@pimunn.net</a>
                                </div>
                                <br></br>
                                <div className='contacts_main_desc'>{t('greetings support')}</div>
                        </div>  
                        <div className='contacts_main_block_selected' id="Предложения">
                            <div className='contacts_main_name_title'><a href="/subscribe">Предложения</a></div>
                            <div className='contacts_main_desc'>
                                {t('By default, the application is available in demo mode')} 
                                <br></br>
                                {t('Subscription is provided by upgrading')}
                                <a href="mailto:sale@pimunn.net"> sale@pimunn.net</a>, {t('or mobile phone')}: <b>+7(920)002-11-55</b>
                                <br></br>
                                {t('subscribs allow')}
                                <br></br>
                                {t('subscription names')}
                                <br></br>
                                {t('university')}
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 900.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.900.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 5.000.000 ₽
                                <br></br>
                                {t('college')}
                                <br></br>
                                1. {t('basic')}: {t('up500')}, {t('usage period')} 1 {t('num_year_1')} - 630.000 ₽
                                <br></br>
                                2. {t('opt')}: {t('from500')}, {t('usage period')} 1 {t('num_year_1')} - 1.330.000 ₽
                                <br></br>
                                3. {t('premium')}: {t('nolimit')}, {t('usage period')} 10 {t('num_year_5')} - 3.250.000 ₽
                            </div>
                        </div >                            
                        <div className='contacts_main_block_selected' id="Демо-доступ">
                            <div className='contacts_main_name_title'><a href="/demo_version">
                            Демо-доступ
                            </a></div>
                            <div className='contacts_main_desc'>
                                {t('All users are by default granted access only to the demo version of the application.')}
                            </div>
                            <br></br>
                            <img className="screen_menu" src={t('screens_demo_case')}></img>
                            <div className='screen_string'>{t('Figure 5.7')}</div>
                        </div>
                    </div>                                  
                </div >  
            </div>
        </div>
    </>;
});

export default Contacts_Page;