/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Otolaryngology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Клиническая фармакология".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Otolaryngology ]

//===========
// begin [ Otolaryngology_Categories ]

export const fetchOtolaryngology_Categories = async (search, language) => {
    const {data} = await $host.get('api/otolaryngology/otolaryngology_categories', { params: { search, language } });
    return data;
}

export const fetchOneOtolaryngology_Categories = async (id) => {
    const { data } = await $host.get('api/otolaryngology/otolaryngology_categories/' + id);
    return data;
}

// end [ Otolaryngology_Categories ]
//===========

//-----------

//===========
// begin [ Otolaryngology_Data ]

export const fetchOtolaryngology_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/otolaryngology/otolaryngology_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOneOtolaryngology_Data = async (id) => {
    const { data } = await $host.get('api/otolaryngology/otolaryngology_data/' + id);
    return data;
}

// end [ Otolaryngology_Data ]
//===========

//// end [ Otolaryngology ]
////===========

//-----------