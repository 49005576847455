/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Start_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница стартовой отвечает за отображение страницы стартовой страницы приложения.
 *	@author: Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PIMU_CATEGORIES_ROUTE } from "../utils/consts";
import "./Start_Page.css"
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import Spline from '@splinetool/react-spline';
import { Context } from "../index";

const Start_Page = observer(() => {
    const history = useNavigate()

    useEffect(() => {

        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => {
        });

        // Updating scrollSpy when the component mounts.
        scrollSpy.update();

        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
        };
    }, []);

    document.addEventListener('DOMContentLoaded', function () {
        var initialScrollPos = 0;

        window.addEventListener('popstate', function () {
            // Восстанавливаем первоначальную позицию прокрутки при возврате назад
            window.scrollTo({ top: initialScrollPos, behavior: 'instant' });
        });
    });

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const { t } = useTranslation();

    return (
        <div className='start_page-content'>
            <div className='start_page-glass-content'>
                <div className="start_page-pic">
                    {
                        //<Spline className='spline_scene' scene="https://prod.spline.design/snUzhjfbhcxWY9C5/scene.splinecode" />
                    }
                    <Spline className='spline_scene' scene='/scene.splinecode' />
                    {/* <img src="/logo_pimu_without.png" alt="pimu" /> */}
                </div>
                <div className='start_page-title'>
                    {t('start_page_title')}

                    <div className='start_page-title-desc'>

                        {t('start_page_title-desc')}
                    </div>
                </div>
                <button
                    className='start_page-button'
                    onClick={() => {
                        history(PIMU_CATEGORIES_ROUTE);
                        scrollToTop(200);
                    }

                    }>
                    {t('navbar_title_authorization_login')}
                </button>
            </div>

            <video id="background-video" className='start_page-video' autoPlay loop muted>
                <source src={"/video.mp4"} type='video/mp4' />
            </video>
        </div>
    );
});

export default Start_Page;
