/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "эндоскопия". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';
import '../../components/Categories_Page.css';
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';

export const Endoscopy_EntriesPerPage = 10;

const Endoscopy_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return (
        <div className='page_content'>
            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/endoscopy_icon.png' alt='page_title_icon'></img>{t("endoscopy_title")}

            </div>
            <div className='page_content_parent'>
                <div className='page_content_flex'>
                    <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
                </div>
                <div className='page_content_rightpage_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                    <div className='page_content_rightpage'>
                        <div className='page_content_title_info'>
                            <b>{t("endoscopy_title")}</b> - {t("endoscopy_page_content_title_info")}
                            <br></br>
                            <br></br>{t("Endoscopy_desc_1")}
                            <br></br>
                            <br></br>{t("Endoscopy_desc_2")}
                            <br></br>
                            <br></br>WLI – {t("WLI")}
                            <br></br>NBI HD – {t("NBI HD")}
                            <br></br>NBI Near Focus – {t("NBI Near Focus")}
                            <br></br>С1 Kimura-Takemoto – {t("C1 Kimura-Takemoto")}
                            <br></br>О1 Kimura-Takemoto – {t("O1 Kimura-Takemoto")}
                            <br></br>BLI HD – {t("BLI HD")}
                            <br></br>BLI HD ME – {t("BLI HD ME")}
                            <br></br>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
});

export default Endoscopy_Categories_Page;