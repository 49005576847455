/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Spo_Data_EntriesItem.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "Биология для среднепрофильного образования". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { Spo_CATEGORIES_ROUTE, Spo_DATA_ENTRIE, DEMO_VERSION_ROUTE } from '../../utils/consts';

const Spo_Data_EntriesItem = ({ item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            history(DEMO_VERSION_ROUTE);
            return;
        }
        scrollToTop();
        history(`${Spo_CATEGORIES_ROUTE}${Spo_DATA_ENTRIE}/${item.id}`);
    };

    return <div className="case_preview-item1" onClick={TryNavigateToCase}>
        {item.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>
            {t("Demo")}
        </div>}
        <img src={item.images_preview_path} alt="image preview" />
        <div className='case_preview_info1'>
            <div className='case_preview_title1'>
                {item['name_' + user.language]}
            </div>
        </div>
    </div>;
};

export default Spo_Data_EntriesItem;