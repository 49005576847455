/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: KLD_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "клд". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SliderProtected from '../../components/ProtectedImageSlider';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { ClenseNumeral, PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { KLD_EntriesPerPage } from './KLD_Categories_Page';
import './KLD_Data_Entrie_Page.css';

const KLD_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();
    const selectedGender = new URLSearchParams(location.search).get('gender');
    const [descriptionIndex, setDescriptionIndex] = useState(0);

    useEffect(() => {
        PrepareItemPage(location, user.language, selectedGender, KLD_EntriesPerPage).then((data) => {
            setPageData({
                ...data,
                item: {
                    ...data.item,
                    description: {
                        ru: data.item.description_ru.split('|'),
                        en: data.item.description_en.split('|'),
                    },
                },
            });
        });
    }, [location, user]);

    // При изменении выбранного изображения, изменить описание.
    const HandleImageChange = (imageIndex) => {
        setDescriptionIndex(imageIndex);
    };

    if (!pageData || !pageData?.item)
        return null;

    return (
        <>
            <div className="page_content_title">
                <img className="page_title_icon" src="/icons/path_icon.png" alt="page_title_icon" />
                {t('kld_title')}
            </div>
            <div className="flex_container">
                <div className="flex_container_title">
                    <div className="item flex_item_1">
                        {pageData.category['name_' + user.language]}
                    </div>
                    <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
                </div>
                <hr />
                <div className="case_birth">
                    <div className='case_flex_onerow'>
                        <div className="case_bold_name">
                            {pageData.item['name_' + user.language]}
                        </div>
                    </div>
                    { (pageData.item.age != null || pageData.item.gender.id != 3) && <div className='gender_age_bar_kld'>
                        <div className='gender'>
                            {pageData.item.gender.id == 3 ? t('gender_unspecified') : t('gender_' + pageData.item.gender.name_en) }
                        </div>
                        <div className='age'>
                            {pageData.item.age ? <>
                                {pageData.item.age}&nbsp;
                                {ClenseNumeral(pageData.item.age, [t('num_year_1'), t('num_year_2'), t('num_year_5')])}
                            </> : <span style={{ fontSize: '1.5rem' }}>
                                {t('age_preview')}&nbsp;{t('case_age_unspeciied')}
                            </span>}
                        </div>
                    </div> }
                    {pageData.item['conclusion_' + user.language] && (
                        <>
                            <div className="case_text_info">{t('conclusion')}</div>
                            <div className="case_text_block">
                                {pageData.item['conclusion_' + user.language]}
                            </div>
                        </>
                    )}

                </div>

                <div className="dzi_section">
                    <SliderProtected
                        imageurl={pageData.item.images_path}
                        onSlideChanged={HandleImageChange}
                        imagesPerView={1}
                    />
                </div>

                {pageData.item.description[user.language][descriptionIndex] && <div>
                    <div className='case_text_info'>{t('description')}</div>
                    <div className='case_text_block'>
                        {pageData.item.description[user.language][descriptionIndex]}
                    </div>
                </div>}
                <br />
                <BottomCaseNavigation pageData={pageData} />
            </div>
        </>
    );
};

export default KLD_Data_Entrie_Page;
