/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Testing_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Testing_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//  Получить список доступных для тестирования категорий.
export const fetchTesting_Categories = async () => {
    const { data } = await $host.post('api/testing/categories');

    return data;
};

//  Получить информацию о показателях, связанных с тестированием.
//  К ним относится: общее количество доступных тестов, количество активных тестов, количество пройденных тестов.
export const fetchTesting_Counters = async () => {
    const { data } = await $host.post('api/testing/counters');

    return data;
};

//  Получить информацию о показателях, связанных с тестированием и конкретным пользователем.
export const fetchTesting_UserCounters = async (userId) => {
    const { data } = await $host.post('api/testing/counters/' + userId);

    return data;
};

//  Получить список тестов доступных для выбранной категории
export const fetchTesting_Category_Tests = async (categoryId, limit) => {
    const { data } = await $host.post('api/testing/categories/' + categoryId, { limit });

    if (data.length)
    {
        const questions = data.map( (t, i) => {
            const nonEmptyAnswers = t['answer_ru'].reduce( (prev, curr) => prev + (curr != '' ? 1 : 0), 0 );

            if (!t['answer_en'])
                return null;

            const nonEmptyEnglishAnswers = t['answer_en'].reduce( (prev, curr) => prev + (curr != '' ? 1 : 0), 0 );

            if (!nonEmptyAnswers || nonEmptyAnswers != nonEmptyEnglishAnswers)
                return null;

            return {
                id: t.id,
                question: {
                    ru: t.question_ru,
                    en: t.question_en,
                },
                answers: t.answer_ru.map( (a, i) => {
                    return { id: i + 1, text: { ru: a, en: t.answer_en[i] } }
                }),
                correct: t.true_answer.map( (a) => Number(a) ),
                images_path: t?.images_path,
            };
        });

        return questions.filter( (q) => q != null );
    }

    return null;
};

//  Запись результов прохождения теста пользователем.
export const recordUserQuiz = async (quizData) => {
    const { data } = await $host.put('api/testing/userdata', quizData);

    return data;
};

//  Получение данных о пройденных тестах для пользователя.
export const fetchTesting_UserData = async (userId) => {
    let { data } = await $host.get('api/testing/userdata/' + userId);
 
    if (data.length)
    {
        /*
        data = data.map( (q) => {
            const c = q.quizid.reduce( (prev, curr, i) => {
                const correctThisQuestion = curr.true_answer.split('|').map(Number);
                const givenAnswersThisQuestion = q.answers[i]?.reduce( (p, c, ii) => p += (c != null && correctThisQuestion.includes(c) ? 1 : 0), 0) ?? 0;

                return prev + (givenAnswersThisQuestion == correctThisQuestion.length ? 1 : 0);
            }, 0);

            return {
                ...q,
                correct: c,
            };
        });
        */

        data = data.map((q) => {
            const c = q.quizid.reduce((prev, curr, i) => {
                const correctThisQuestion = curr.true_answer.split('|').map(Number);
                const givenAnswersThisQuestion = q.answers[i]?.filter(Boolean) || []; // Убираем null/undefined
                
                // Проверяем что:
                // 1. Количество ответов совпадает
                // 2. Все ответы правильные
                const isPerfectMatch = 
                    givenAnswersThisQuestion.length === correctThisQuestion.length &&
                    givenAnswersThisQuestion.every(ans => correctThisQuestion.includes(ans));
                
                return prev + (isPerfectMatch ? 1 : 0);
            }, 0);
        
            return {
                ...q,
                correct: c,
            };
        });
    }

    return data;
};