/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Data_EntriesItem.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "Аускультация". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { Auscultation_CATEGORIES_ROUTE, Auscultation_DATA_ENTRIE, DEMO_VERSION_ROUTE } from '../../utils/consts';

const Auscultation_Data_EntriesItem = ({ item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            history(DEMO_VERSION_ROUTE);
            return;
        }
        scrollToTop();
        history(`${Auscultation_CATEGORIES_ROUTE}${Auscultation_DATA_ENTRIE}/${item.id}`);
    };

    return <div className='ecg_data_entires_item-list' onClick={TryNavigateToCase}>
        <div className='ecg_data_entries_item-items'>
            <div className='data_entries_item_info'>
                <div className='icon_patient'>
                    <img src='/icons/icon_auscultacia_case.png' />
                </div>
                 <hr className='ecg_data_entries_hr'></hr>
                <div className='data_entries_item-name'>
                    {item['name_' + user.language]}
                </div>
                {item.demo_access && user.access_level.demo_access && <div className='ecg_demo'>{t('Demo')}</div>}
            </div>
        </div>
    </div>;
};

export default Auscultation_Data_EntriesItem;