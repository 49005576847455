/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "Аускультация". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import { useContext, useState, useEffect } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { useLocation } from 'react-router-dom';
import { PreparePage } from '../../utils/PageDataPrepare';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';

export const Auscultation_EntriesPerPage = 8;

const Auscultation_Categories_Page = observer(() => {
    const { user } = useContext(Context)
    const { t } = useTranslation();
    const location = useLocation();
    const [ pageData, setPageData ] = useState({});

    useEffect( () => {
        PreparePage(location, user.language, user.search, user.page).then( (data) => setPageData(data) );
    }, [ location, user, user.search ]);

    if (!pageData || !pageData?.subCategory)
        return;

    return (
        <div className='page_content'>
            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/auscultation_icon.png' alt='page_title_icon'></img>{t("auscultation_title")}
            </div>
            <div className='page_content_parent'>
                <div className='page_content_flex'>
                    <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
                </div>
                <div className='page_content_rightpage_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                    <div className='page_content_rightpage'>
                        <div className='page_content_title_info'>
                            {t("auscultation_desc_1")}
                            <br/>
                            <br/>
                            {t("auscultation_desc_2")}
                            <br/>
                            <br/>
                            {t("auscultation_desc_3")}
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
});

export default Auscultation_Categories_Page;