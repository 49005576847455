/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "Аускультация". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Card, Container } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Auscultation_Data_EntriesList from "../../components/Auscultation/Auscultation_Data_EntriesList";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { Auscultation_EntriesPerPage } from './Auscultation_Categories_Page';

const Auscultation_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Auscultation_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/auscultation_icon.png' alt='page_title_icon'></img>{t('auscultation_title')}
        </div>
        <Container>
            <div className='content_breadcrumbs'>
                <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            </div>
            <div className="mt-2">
                <div  md={9}>
                    <Auscultation_Data_EntriesList pageData={pageData} />
                    <div className='content_Pages'>
                        <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                    </div>
                </div>
            </div>
        </Container>
    </>
});

export default Auscultation_Data_EntriesTable_Page;