/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Quiz.jsx
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Quiz.jsx
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
import { useState, useContext, useEffect } from 'react';
import './Quiz.css';
import { Context } from "../index";
import { useTranslation } from 'react-i18next';
import DZI_Images_Display_Quiz from './DZI_OpenSeadragon/DZI_Images_Display_Quiz';
import ECG_Data_Images_Display from './ECG/ECG_Data_Images_Display';


/*
const QuizButton = ({ option, language, isClickable, isDisabled, showCorrect, onAnswered }) => {
    const buttonClassNames = ['button'];

    if (!isClickable && showCorrect)
        buttonClassNames.push(option.correct ? 'correct' : 'incorrect');

    return <button data-id={option.id} disabled={isDisabled || !isClickable} className={buttonClassNames.join(' ')} onClick={onAnswered}>
        {option.text[language]}
    </button>;
};
*/

//  Компонент для кнопки вопроса.
//  Вынесен отдельно т.к. стало набираться слишком много логики, которую нужно обрабатывать.
const QuizButton_2 = ({ option, language, showCorrect, onAnswered, isSelected2}) => {
    const buttonClassNames = ['button'];

    const handleClick = (same) => {
        onAnswered(same);
    };

    if (isSelected2) {
        buttonClassNames.push('btn_selected');
    }

    if (showCorrect && isSelected2) {
        if (option.correct) {
            buttonClassNames.push('correct');
        } else {
            buttonClassNames.push('incorrect');
        }
    } else if (showCorrect && !isSelected2 && option.correct) {
        buttonClassNames.push('correct_no_chosen');
    }
    /*
    if (showCorrect && !isSelected && option.correct) {
        buttonClassNames.push('correct_no_chosen');
    }*/

    if (showCorrect && option.correct) {
        buttonClassNames.push('correct');
    } else if (showCorrect && isSelected2 && !option.correct) {
        buttonClassNames.push('incorrect');
    }

    return (
        <button
            data-id={option.id}
            className={buttonClassNames.join(' ')}
            onClick={handleClick}
            disabled={showCorrect}
        >
            {option.text[language]}
        </button>
    );
};

const QuizComponent = ({ testData, showCorrect, onAnswer, selectedQuestions }) => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const [selectedAnswers, setSelectedAnswers] = useState(selectedQuestions);
    const [showResults, setShowResults] = useState(false);
    
    // Отправляю ответ в родительский компонент при изменении ответа
    useEffect(() => {
        onAnswer(selectedAnswers);
    }, [selectedAnswers]);

    if (!testData)
        return;

    let quizOptions = [];
    const isDemo = user.access_level.demo_access;

    if (!isDemo) {
        quizOptions = testData.answers.map((a) => {
            return {
                ...a,
                correct: testData.correct.includes(a.id),
                type: 'button',
            }
        });
    }
    else {
        quizOptions = [
            {
                id: 1,
                text: {
                    ru: 'Вариант ответа 1',
                    en: 'Answer 1',
                },
                correct: true,
                type: 'button'
            },
            {
                id: 2,
                text: {
                    ru: 'Вариант ответа 2',
                    en: 'Anser 2'
                },
                correct: false,
                type: 'button'
            },
            {
                id: 3,
                text: {
                    ru: 'Вариант ответа 3',
                    en: 'Answer 3'
                },
                correct: false,
                type: 'button',
            }
        ];
    }

    const correctAnswers = quizOptions.filter(({ correct }) => correct === true).length;

    const onAnswered = (event) => {
        const answerId = event.target.dataset.id;
        if (!answerId || isDemo)
            return;

        const answerData = quizOptions.find(({ id }) => id == answerId);
        if (!answerData)
            return;

        setSelectedAnswers((prev) => {
            const isAlreadySelected = prev.some(({ id }) => id == answerId);
            if (isAlreadySelected) {
                // Если ответ уже выбран, снимаем выбор
                return prev.filter(({ id }) => id != answerId);
            } else {
                if (correctAnswers > 1) {
                    // Если можно дать несколько вариантов ответа, то добавляем ответ
                    return [...prev, answerData];
                } else {
                    // Иначе заменяем его текущим
                    return [answerData];
                }      
            }
        });
    };

    const handleCheckAnswers = () => {
        if (selectedAnswers.length > 0) {
            if (showCorrect) {
                setShowResults(true);
            }
        }
    };



    const Results = () => {
        //if (selectedAnswers.length != correctAnswers)
        //    return;

        const answersSelected = selectedAnswers.reduce((prev, curr) => prev = [...prev, curr.text[user.language]], []).join(', ');
        const correctAnswersText = quizOptions.filter(({ correct }) => correct == true).reduce((prev, curr) => prev = [...prev, curr.text[user.language]], []).join(', ');
        const answeredCorrectly = selectedAnswers.reduce((prev, curr) => prev += curr.correct ? 1 : 0, 0);

        let answerResultHintToken;
        if (answeredCorrectly == correctAnswers)
            answerResultHintToken = 'quiz_answer_correct_fully';
        else
            if (answeredCorrectly >= 1)
                answerResultHintToken = 'quiz_answer_correct_partial';
            else
                answerResultHintToken = 'quiz_answer_incorrect';

        return <span className='quiz_answer_text_selected'>
            {t('quiz_answer_text_selected')}&nbsp;{answersSelected}.
            <br />
            <div className='correct_answer_hint'>{t(answerResultHintToken)}</div>
            <br />
            <div className='correct_answer'>
                {`${t('quiz_answer_correct_list')} ${correctAnswersText}`}
            </div>
        </span>;
    };

    return <div className={`quiz${isDemo ? ' demo' : ''}`}>
        <div className='heading'>
            {testData.question[user.language]}
        </div>
        <div className='image_dzi_or_ekg'>
            {testData?.images_path && testData?.images_path[0].endsWith('.dzi') ? < DZI_Images_Display_Quiz images={testData?.images_path} /> : <ECG_Data_Images_Display imageurl={testData?.images_path} imagesPerView={1} />}
        </div>
        <div className='hint'>
            {t('quiz_hint_answers')}&nbsp;
            {correctAnswers > 1 ? t('quiz_hint_answers_multiple') : t('quiz_hint_answers_one')}
        </div>
        <div className='options'>
            {
                // quizOptions.map((option) => <QuizButton key={option.id} option={option} onAnswered={onAnswered} language={user.language} isClickable={selectedAnswers.length != correctAnswers} isDisabled={selectedAnswers.find(({ id }) => id == option.id)} showCorrect={showCorrect} />)
                quizOptions.map((option) => <QuizButton_2 
                                                key={option.id} 
                                                option={option} 
                                                onAnswered={onAnswered} 
                                                language={user.language}
                                                isSelected2={selectedAnswers.find(({ id }) => id == option.id)}
                                                showCorrect={showCorrect && showResults} 
                                            />)
            }
        </div>
        <br></br>
        {showCorrect && <button className='btn_results' onClick={handleCheckAnswers}>
                {t('Сheck the answers')}
        </button>}

        {showCorrect && showResults && <div className='results'>
            <Results />
        </div>}
        {isDemo && <div className='demo_text'>
            {t('quiz_demo_text', { returnObjects: true }).map((v, i) => <span key={i}><span dangerouslySetInnerHTML={{ __html: v }}></span><br /></span>)}
        </div>}
    </div>
};

export default QuizComponent;