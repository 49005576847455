/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Spo_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Биология для среднепрофильного образования".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Spo ]

//===========
// begin [ Spo_Categories ]

export const fetchSpo_Categories = async (search, language) => {
    const {data} = await $host.get('api/spo/spo_categories', { params: { search, language } });
    return data;
}

export const fetchOneSpo_Categories = async (id) => {
    const { data } = await $host.get('api/spo/spo_categories/' + id);
    return data;
}

// end [ Spo_Categories ]
//===========

//-----------

//===========
// begin [ Spo_Data ]

export const fetchSpo_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/spo/spo_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOneSpo_Data = async (id) => {
    const { data } = await $host.get('api/spo/spo_data/' + id);
    return data;
}

// end [ Spo_Data ]
//===========

//// end [ Spo ]
////===========

//-----------