import { useState, useEffect } from 'react';
import { deleteControlledUser, getMyUsers, getRoles, prepareUsersExportList, registerControlledUser, registerControlledUserEnMasse, resetPasswordControlledUser, updateControlledUser } from '../../http/User_API';
import { useTranslation } from 'react-i18next';

const MyUsersUniversity = ({ user, error, setError }) => {
    const { t } = useTranslation();
    const [ roles, setRoles ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ editUser, setEditUser ] = useState(null);
    const [ search, setSearch ] = useState({ type: null, text: null });

    useEffect( () => {
        //  Запрос списка доступных ролей и непосредственных пользователей, где текущий пользователь является управляющим (родителем).
        const RequestRoles = async () => {
            const rolesList = await getRoles({ can_select: true });
            if (rolesList?.roles.length)
                setRoles(rolesList.roles.filter( r => r.id !== 'pupil' ));
        };

        const RequestUsers = async () => {
            const usersList = await getMyUsers(user.profile.id);
            if (usersList?.users.length)
                setUsers(usersList.users);
        };

        RequestRoles();
        RequestUsers();
    }, [] );

    //  Запрос на создание нового пользователя.
    const CreateControlledUSer = async (event) => {
        event.preventDefault();

        try
        {
            const requestData = {};
            new FormData(event.target).forEach( (v, k) => requestData[k] = v );

            if (!requestData.email)
                throw new Error(`Необходимо указать e-mail!`);

            if (!requestData.firstName)
                throw new Error(`Необходимо указать Имя!`);

            if (!requestData.lastName)
                throw new Error(`Необходимо указать Фамилию!`);

            if (!requestData.role)
                throw new Error(`Необходимо выбрать роль!`);

            const response = await registerControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result || !response?.user)
                throw new Error(response?.message ?? 'Не удалось создать пользователя');

            setError({ result: true, message: null, where: null });
            setUsers( (prev) => {
                return [
                    ...prev,
                    response.user,
                ];
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на удаление пользователя, контроль над которым осуществляется другим пользователем.
    const DeleteControlledUser = async (event) => {
       /* if (!confirm(t('profile_myusers_confirm_delete')))
            return; */

        try
        {
            const requestData = {
                id: event.target.dataset.userid,
            };

            const response = await deleteControlledUser(requestData);
            if (!response)
                throw new Error('Не удалось выполнить запрос');

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось удалить пользователя');

            setError({ result: true, message: null, where: null });
            setUsers( (prev) => {
                return prev.filter( ({ id }) => id != requestData.id );
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на обновление информации о пользователе.
    const UpdateControlledUserInfo = async (event) => {
        event.preventDefault();

        try
        {
            const requestData = {};
            new FormData(event.target).forEach( (v, k) => requestData[k] = v );
            requestData.id = editUser.id;

            if (!requestData.email)
                throw new Error(`Необходимо указать e-mail!`);

            if (!requestData.firstName)
                throw new Error(`Необходимо указать Имя!`);

            if (!requestData.lastName)
                throw new Error(`Необходимо указать Фамилию!`);

            if (!requestData.role)
                throw new Error(`Необходимо выбрать роль!`);

            const response = await updateControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result || !response?.user)
                throw new Error(response?.message ?? 'Не удалось обновить пользователя');

            setError({ result: true, message: null, where: null });

            setEditUser(null);

            event.target.reset();

            setUsers( (prev) => {
                return [
                    ...prev.map( (u) => u.id == response.user.id ? response.user : u )
                ];
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на сброс пароля.
    const PasswordResetRequest = async (event) => {
        try
        {
            const requestData = {
                id: editUser.id,
            };

            const response = await resetPasswordControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось сбросить пароль пользователя');

            setError({ result: true, message: null, where: null });

            setEditUser(null);
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    const MassCreateControlledUsers = async (event) => {
        try
        {
            event.preventDefault();

            const requestData = {
                usersnumber: 0,
                role: null,
            };

            new FormData(event.target).forEach( (v, k) => requestData[k] = v );

            requestData.usersnumber = Number(requestData.usersnumber);

            if (requestData.usersnumber <= 0)
                throw new Error(`Количество пользователей должно быть больше нуля!`);

            if (requestData.usersnumber >= user.profile.profile.usersleft)
                throw new Error(`Количество пользователей должно быть меньше максимально допустимого!`);

            const response = await registerControlledUserEnMasse(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось создать пользователей');

            window.location.reload();

            setError({ result: true, message: null, where: null });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usersmasscreate'
            })
        }
    };

    const UsersExportList = async (event) => {
        try
        {
            event.preventDefault();

            const response = await prepareUsersExportList();

            //  React не даёт открыть ссылку вида 'blob:' из скрипта.
            //  Потому, нужно 'эмулировать' такое поведение - создать элемент-ссылку на 'blob' и "нажать" на неё.
            const href = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'result.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setError({ result: true, message: null, where: null });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'list'
            });
        }
    };

    const HandleUserFilters = (val) => {
        if (search.type == null || search.text == null || search.text == '')
            return true;

        if (search.type == 'search')
            return val.email?.includes(search.text) || val.profile?.firstName?.includes(search.text) || val.profile?.lastName?.includes(search.text);

        if (search.type == 'role')
            return val.role == search.text;

        return false;
    };

    return <div className='users_block'>
        <p className='heading'>{t('profile_myusers_title')}</p>
        <p>{t('profile_myusers_hint')}</p>

        { user?.profile?.profile?.usersleft > 0 ? <div className='addnew_block mb-4'>
            <form className='form mt-2' onSubmit={editUser ? UpdateControlledUserInfo : CreateControlledUSer}>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.email} name='email' required />
                    <label className='form-label'>{t('profile_info_email')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.firstName} name='firstName' required />
                    <label className='form-label'>{t('profile_edit_name')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.lastName} name='lastName' required />
                    <label className='form-label'>{t('profile_edit_surname')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.faculty} name='faculty' required />
                    <label className='form-label'>{t('profile_edit_faculty')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <select className='form-select' name='role' defaultValue={editUser?.role} required>
                        {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option>)}
                    </select>
                    <label className='form-label'>{t('profile_info_role')}</label>
                </div>
                {!error.result && error.message && error.where == 'usercreate' && <div className='alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{error.message}</span>
                </div>}
                <div className='d-flex justify-content-between'>
                    <div className='form-floating'>
                        <div className='input-group'>
                            <button type='submit' className='btn btn-primary'>{editUser ? t('profile_edit_save') : t('profile_edit_create')}</button>
                            {editUser && <button type='button' className='btn btn-danger' onClick={PasswordResetRequest}>{t('profile_myusers_resetpassword')}</button>}
                            {editUser && <button type='button' className='btn btn-secondary' onClick={ (e) => { setEditUser(null); setError({ result: true, message: null, where: null }) } }>{t('profile_myusers_cancel')}</button>}
                        </div>
                    </div>
                    <div className='p-2 text-secondary fs-6'>
                        {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
                    </div>
                </div>
            </form>
        </div> : <div className='mb-5 mt-4'>
            <div className='alert alert-danger'>
                {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
            </div>
        </div> }

        <div className='mass_create_field'>
            <p>{t('profile_myusers_masscreate')}</p>
            <form className='form mt-2' onSubmit={MassCreateControlledUsers}>
                <div className='row'>
                    <div className='col'>
                        <input type='number' className='form-control' name='usersnumber' placeholder={t('profile_myusers_masscreate_number')} required />
                    </div>
                    <div className='col'>
                        <select className='form-select' name='role' required>
                            {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option> )}
                        </select>
                    </div>
                </div>

                {!error.result && error.message && error.where == 'usersmasscreate' && <div className='mt-2 alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{error.message}</span>
                </div>}

                <div className='row mt-2'>
                    <div className='col'>
                        <input className='btn btn-primary' type='submit' value={t('profile_edit_create')} />
                    </div>
                </div>
            </form>
        </div>

        <div className='users_list_filter mt-5 mb-2'>
            <div className='row'>
                <div className='col'>
                    <div className='input-group'>
                        <div className='input-group-text'>{t('search_block_button')}</div>
                        <input type='text' className='form-control' placeholder={t('profile_myusers_search_hint')} required onKeyDown={ event => event.key == 'Enter' && setSearch({ text: event.target.value != '' ? event.target.value : null, type: 'search' }) } />
                        {search.text != null && search.type == 'search' && <button className='btn btn-sm btn-outline-secondary' onClick={ event => setSearch({ type: null, text: null }) }>сброс</button>}
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='input-group'>
                        <div className='input-group-text'>{t('profile_myusers_filter_role')}</div>
                        <select className='form-select' onChange={ event => setSearch({ text: event.target.value, type: 'role' }) }>
                            <option value=''>Любая</option>
                            {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option> )}
                        </select>
                    </div>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-outline-secondary' onClick={UsersExportList}>{t('profile_myusers_export')}</button>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    {!error.result && error.where == 'list' && <div className='mt-2 alert alert-danger fs-6'>
                        <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                        <span>{error.message}</span>
                    </div>}
                </div>
            </div>
        </div>

        <table className='table table-striped table-hover'>
            <thead>
                <tr>
                    <th>{t('profile_info_email')}</th>
                    <th>{t('profile_edit_name')},&nbsp;{t('profile_edit_surname')}</th>
                    <th>{t('profile_info_role')}</th>
                    <th>{t('profile_myusers_actions_buttons')}</th>
                </tr>
            </thead>
            <tbody>
                {!users.length && <tr><td colSpan={4} className='text-center p-4'>{t('profile_myusers_nousers')}</td></tr>}
                {users.filter(HandleUserFilters).map( (user) => <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.profile.firstName}&nbsp;{user.profile.lastName}</td>
                    <td>{t(roles.find( ({ id }) => id == user.role )?.title)}</td>
                    <td>
                        <div className='input-group'>
                            <button data-userid={user.id} onClick={ () => { setEditUser(user); setError({ result: true, message: null, where: null }); } } className='btn btn-sm btn-outline-secondary'>{t('profile_myusers_edit')}</button>
                            <button data-userid={user.id} onClick={DeleteControlledUser} className='btn btn-sm btn-outline-danger'>{t('profile_myusers_delete')}</button>
                        </div>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
};

export default MyUsersUniversity;