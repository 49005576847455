import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { deleteControlledUser, getMyUsers, prepareUsersExportList, registerControlledUser, registerControlledUserEnMasse, resetPasswordControlledUser, updateControlledUser } from '../../http/User_API';

const reducerAction = (state, action) => {
    switch (action.type)
    {
        case 'setsearch':
        {
            return {
                ...state,
                search: action.search
            };
        }
        case 'resetsearch':
        {
            return {
                ...state,
                search: null
            };
        }
        case 'seterror':
        {
            return {
                ...state,
                error: {
                    message: action.message,
                    result: false,
                    where: action.where,
                }
            };
        }
        case 'setsuccess':
        {
            return {
                ...state,
                error: {
                    message: action.message,
                    result: true,
                    where: action.where
                }
            };
        }
        case 'reseterror':
        {
            return {
                ...state,
                error: {
                    message: null,
                    result: null,
                    where: null
                }
            };
        }
        case 'setedit':
        {
            return {
                ...state,
                inEdit: {
                    id: action.userid,
                    user: action.user
                }
            }
        }
        case 'resetedit':
        {
            return {
                ...state,
                inEdit: null
            }
        }
        default:
            break;
    }
};

//  Блок с информацией о пользователях для школы.
const MyUsersSchool = ({ user }) => {
    const { t } = useTranslation();
    const [ action, dispatchAction ] = useReducer(reducerAction, { search: null, searchType: null, inEdit: null, error: { where: null, message: null, result: null } });
    const [ myUsers, setMyUsers ] = useState([]);
    const editFormRef = useRef(null);

    useEffect( () => {
        getMyUsers(user.profile.id).then( u => u?.result ? setMyUsers(u.users) : setMyUsers([]) );
    }, [] );

    const handleUserFilter = (value) => {
        if (!action.search || action.search === '')
            return true;
        else
            return value?.profile?.firstName?.includes(action.search) || value?.profile?.lastName?.includes(action.search) || value?.email?.includes(action.search);
    };

    //  Запрос на удаление пользователя.
    const handleDeleteMyUser = async (id) => {
        try
        {
            dispatchAction({ type: 'reseterror' });

            if (!window.confirm(t('profile_myusers_confirm_delete')))
                return;

            const data = await deleteControlledUser({ id });
            if (!data?.result)
                throw new Error(data.message);

            setMyUsers( p => (p.filter( u => u.id !== id )) );
            dispatchAction({ type: 'reseterror' });
        }catch (error)
        {
            dispatchAction({ type: 'seterror', where: 'usercreate', message: error.message });
        }
    };

    //  Массовое создание сразу нескольких пользователей.
    const handleCreateMassUsers = async (event) => {
        try
        {
            dispatchAction({ type: 'reseterror' });
            event.preventDefault();

            const requestData = {};
            new FormData(event.target).forEach( (v, k) => requestData[k] = v );

            requestData.role = 'pupil';
            requestData.usersnumber = Number(requestData.usersnumber);

            const data = await registerControlledUserEnMasse(requestData);

            if (!data?.result)
                throw new Error(data.message);

            const newUsers = await getMyUsers(user.profile.id);

            if (!newUsers?.result)
                throw new Error(newUsers.message);

            setMyUsers(newUsers.users);

            dispatchAction({ type: 'reseterror' });

            event.target.reset();
        }catch (error)
        {
            dispatchAction({ type: 'seterror', where: 'usersmasscreate', message: error.message });
        }
    }

    //  Экспорт списка пользователей.
    const handleExportMyUsers = async () => {
        try
        {
            dispatchAction({ type: 'reseterror' });

            const data = await prepareUsersExportList();

            const href = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'result.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            dispatchAction({ type: 'reseterror' });
        }catch (error)
        {
            dispatchAction({ type: 'seterror', where: 'list', message: error.message });
        }
    }

    //  Создание нового пользователя для школы.
    const handleCreateMyUser = async (event) => {
        try
        {
            dispatchAction({ type: 'reseterror' });
            event.preventDefault();

            const userData = {};
            new FormData(event.target).forEach( (v, k) => userData[k] = v );

            userData.role = 'pupil';

            const data = await registerControlledUser(userData);
            if (data?.message && !data?.result)
                throw new Error(data.message);

            dispatchAction({ type: 'reseterror' });
            dispatchAction({ type: 'setsuccess', message: 'profile_user_create_success', where: 'usercreate' });

            setMyUsers( p => ([
                ...p,
                data.user
            ]) );

            event.target.reset();
        }catch (error)
        {
            dispatchAction({ type: 'seterror', message: error.message, where: 'usercreate' });
        }
    }

    //  Отображение формы редактирования пользователя.
    const handleEditUser = (id) => {
        dispatchAction({ type: 'setedit', userid: id, user: myUsers.find( u => u.id === id ) });
    };

    //  Закрытие модального окна с редактированием информации о пользователе.
    const handleModalClose = () => {
        dispatchAction({ type: 'resetedit' });
    };

    //  Сохранение данных в модальном окне - редактирование пользователя.
    const handleModalSave = async () => {
        if (!editFormRef)
            return;

        try
        {
            dispatchAction({ type: 'reseterror' });

            const requestData = {};
            new FormData(editFormRef.current).forEach( (v, k) => requestData[k] = v );

            requestData.id = action.inEdit.id;

            const data = await updateControlledUser(requestData);

            if (!data?.result)
                throw new Error(data.message);

            editFormRef.current.reset();

            dispatchAction({ type: 'reseterror' });

            handleModalClose();

            const newUsers = await getMyUsers(user.profile.id);

            if (!newUsers?.result)
                throw new Error(newUsers.message);

            setMyUsers(newUsers.users);
        }catch (error)
        {
            dispatchAction({ type: 'seterror', message: error.message, where: 'usereditmodal' });
        }
    };

    //  Сохранение информации о редактированном пользователе.
    const handleEditUserSubmit = async (event) => {
        event.preventDefault();

        handleModalSave();
    };

    //  Сброс пароля у пользователя.
    const handlePasswordReset = async (event) => {
        try
        {
            if (!window.confirm(t('profile_myusers_resetpassword_confirm')))
                return;

            const data = await resetPasswordControlledUser({ id: action.inEdit.id });

            if (!data?.result)
                throw new Error(data.message);

            handleModalClose();
        }catch (error)
        {
            dispatchAction({ type: 'seterror', message: error.message, where: 'usereditmodal' });
        }
    };

    if (!user)
        return;

    return <div className='users_block'>
        <p className='heading'>{t('profile_myusers_school_title')}</p>
        <p>{t('profile_myusers_hint')}</p>

        { user?.profile?.profile?.usersleft > 0 ? <div className='addnew_block mb-4'>
            <form className='form mt-2' onSubmit={handleCreateMyUser}>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' name='email' required />
                    <label className='form-label'>{t('profile_info_email')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' name='firstName' required />
                    <label className='form-label'>{t('profile_edit_name')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' name='lastName' required />
                    <label className='form-label'>{t('profile_edit_surname')}</label>
                </div>
                {!action.error.result && action.error.message && action.error.where === 'usercreate' && <div className='alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{action.error.message}</span>
                </div>}
                <div className='d-flex justify-content-between'>
                    <div className='form-floating'>
                        <div className='input-group'>
                            <button type='submit' className='btn btn-primary'>{t('profile_edit_create')}</button>
                        </div>
                    </div>
                    <div className='p-2 text-secondary fs-6'>
                        {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
                    </div>
                </div>
            </form>
        </div> : <div className='mb-5 mt-4'>
            <div className='alert alert-danger'>
                {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
            </div>
        </div> }

        <div className='mass_create_field'>
            <p>{t('profile_myusers_masscreate')}</p>
            <form className='form mt-2' onSubmit={handleCreateMassUsers}>
                <div className='row'>
                    <div className='col'>
                        <input type='number' className='form-control' name='usersnumber' placeholder={t('profile_myusers_masscreate_number')} required />
                    </div>
                </div>

                {!action.error.result && action.error.message && action.error.where === 'usersmasscreate' && <div className='mt-2 alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{action.error.message}</span>
                </div>}

                <div className='row mt-2'>
                    <div className='col'>
                        <input className='btn btn-primary' type='submit' value={t('profile_edit_create')} />
                    </div>
                </div>
            </form>
        </div>

        <div className='users_list_filter mt-5 mb-2'>
            <div className='row'>
                <div className='col'>
                    <div className='input-group'>
                        <input type='text' className='form-control' placeholder={t('profile_myusers_search_hint')} required onKeyDown={ e => dispatchAction({ type: 'setsearch', search: e.target.value }) } />
                        {action.search != null && <button className='btn' onClick={ e => dispatchAction({ type: 'resetsearch' }) }>сброс</button>}
                    </div>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-outline-secondary' onClick={handleExportMyUsers}>{t('profile_myusers_export')}</button>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    {!action.error.result && action.error.where === 'list' && <div className='mt-2 alert alert-danger fs-6'>
                        <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                        <span>{action.error.message}</span>
                    </div>}
                </div>
            </div>
        </div>

        <div className="table-container">
            <table className='table table-striped table-hover'>
                <thead>
                    <tr>
                        <th>{t('profile_info_email')}</th>
                        <th>{t('profile_edit_name')},&nbsp;{t('profile_edit_surname')}</th>
                        <th>{t('profile_myusers_actions_buttons')}</th>
                    </tr>
                </thead>
                <tbody>
                    {!myUsers.length && <tr><td colSpan={4} className='text-center p-4'>{t('profile_myusers_nousers')}</td></tr>}
                    {myUsers.filter(handleUserFilter).map(u => (
                        <tr key={u.id}>
                            <td>{u.email}</td>
                            <td>{u.profile.firstName}&nbsp;{u.profile.lastName}</td>
                            <td>
                                <div className='input-group'>
                                    <button className='btn btn-sm btn-outline-secondary' onClick={e => handleEditUser(u.id)}>{t('profile_myusers_edit')}</button>
                                    <button onClick={e => handleDeleteMyUser(u.id)} className='btn btn-sm btn-outline-danger'>{t('profile_myusers_delete')}</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <Modal
            show={action.inEdit}
            backdrop='static'
            size='lg'
            keyboard={false}
            onHide={handleModalClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Редактирование</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleEditUserSubmit} ref={editFormRef} className='p-3'>
                    <div className='row form-floating mb-3'>
                        <input type='text' name='email' className='form-control' required defaultValue={action.inEdit?.user?.email} />
                        <label>{t('profile_info_email')}</label>
                    </div>
                    <div className='row form-floating mb-3'>
                        <input type='text' name='firstName' className='form-control' required defaultValue={action.inEdit?.user?.profile?.firstName} />
                        <label>{t('profile_edit_name')}</label>
                    </div>
                    <div className='row form-floating mb-3'>
                        <input type='text' name='lastName' className='form-control' required defaultValue={action.inEdit?.user?.profile?.lastName} />
                        <label>{t('profile_edit_surname')}</label>
                    </div>
                </form>
                {(!action?.error?.result && action?.error?.message && action?.error?.where === 'usereditmodal') && <div className='mt-2 alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{action.error.message}</span>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-secondary' onClick={ e => handleModalClose() }>{t('profile_myusers_cancel')}</button>
                <button className='btn btn-danger' onClick={ e => handlePasswordReset() }>{t('profile_myusers_resetpassword')}</button>
                <button className='btn btn-primary' onClick={ e => handleModalSave() }>{t('profile_edit_save')}</button>
            </Modal.Footer>
        </Modal>
    </div>;

    //                            {<button type='button' className='btn btn-danger'>{t('profile_myusers_resetpassword')}</button>}
    //{<button type='button' className='btn btn-secondary'>{t('profile_myusers_cancel')}</button>}
};

export default MyUsersSchool;