/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Auscultation_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Аускультация".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Auscultation ]

//===========
// begin [ Auscultation_Categories ]

export const fetchAuscultation_Categories = async (search, language) => {
    const {data} = await $host.get('api/auscultation/auscultation_categories', { params: { search, language } });
    return data;
}

export const fetchOneAuscultation_Categories = async (id) => {
    const { data } = await $host.get('api/auscultation/auscultation_categories/' + id);
    return data;
}

// end [ Auscultation_Categories ]
//===========

//-----------

//===========
// begin [ Auscultation_Data ]

export const fetchAuscultation_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/auscultation/auscultation_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOneAuscultation_Data = async (id) => {
    const { data } = await $host.get('api/auscultation/auscultation_data/' + id);
    return data;
}

// end [ Auscultation_Data ]
//===========

//// end [ Auscultation ]
////===========

//-----------