import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Context } from '../index';
import Pagination from '../components/Pagination';
import { PreparePage, FetchTable} from '../utils/PageDataPrepare';
import * as newsData from '../utils/news.json';

import './News_Page.css';

const useCategoriesData = (categoriesList, language) => {
  const [categories, setCategories] = useState([]);
  const [totalCases, setTotalCases] = useState(0);
  const { user } = useContext(Context);

   useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const data = await Promise.all(categoriesList.map(async (category) => {
          const pageData = await PreparePage(
            { pathname: `/${category}`, search: '' },
            user.language,
            null,
            1,
            10,
            null,
            null,
            []
          );
          return {
            name: pageData.primaryCategory?.[`name_${user.language}`] || pageData.primaryCategory?.name_ru,
            count: pageData.items?.count || 0,
            slug: category 
          };
        }));

        const filteredData = data.filter(category => category.count > 0);
        setCategories(filteredData);

        const totalCases = filteredData.reduce((acc, category) => acc + category.count, 0);
        setTotalCases(totalCases);

      } catch (error) {
        console.error('Ошибка при загрузке категорий:', error);
      }
    };
    fetchCategoriesData();
  }, [user.language]);

  return { categories, totalCases };
};

const getCaseWord = (count, language ) => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (language === 'en') {
        return lastDigit === 1 ? "case" : "cases";
    }
    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return "кейсов";
    }
    if (lastDigit === 1) {
        return "кейс";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return "кейса";
    } else {
        return "кейсов";
    }
};

const NewsItem = ({ item }) => {
  const { t } = useTranslation('news_translation');

  return ( 
    <motion.div
      className="news-item"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <div className="news-item-image">
        <img src={item.image} alt={item.title} />
      </div>
      <div className="news-item-text">
        <span className="news-item-date">{item.date}</span>
        <h3><a href={item.link}>{item.title}</a></h3>
        <p>{item.text}</p>

      </div>
    </motion.div>
  );
};

const NewsPage = () => {
    const { t } = useTranslation('news_translation');
    const { user } = useContext(Context);
    const navigate = useNavigate();
    const categoriesList = Object.keys(FetchTable);

    const sortedNews = Array.from(newsData).sort((a, b) => b.id - a.id);

    const news = sortedNews.map( n => ({
      ...n,
      title: t(n.title),
      text: t(n.text)
    }))

    const { categories, totalCases } = useCategoriesData(categoriesList, user.language);

    const [currentPage, setCurrentPage] = useState(1);
    const newsPerPage = 4;

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = news?.slice(indexOfFirstNews, indexOfLastNews);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const GoToCategory = (category) => {
        navigate(`/pimu/${category.toLowerCase()}`);
    };

    return (
        <div className="news-page">
            <aside className="news-sidebar">
                <div className="statistics">
                    <h3>{t('statistics.title')}</h3>
                     <p>{t('statistics.totalCategories', { count: categories.length })}</p>
                    <p>{t('statistics.totalCases', { count: totalCases })}</p>
                </div>
                <div className="news-categories-container">
                    <ul>
                        {categories.length > 0 ? (
                        [...categories, ...categories].map((category, index) => (
                            <li key={index} className="news-about_categories_line" onClick={() => GoToCategory(category.slug)}>
                                <b>{category.name}</b> <br />
                                {category.count} {getCaseWord(category.count, user.language)}
                            </li>
                        ))
                        ) : (
                            <li>{t('statistics.loading')}</li>
                        )}
                    </ul>
                </div>
            </aside>

            <div className="news-content">
                <main className="news_page-content fade-in">
                  <h1 className="news_page-title" id="smooth">
                      {t('news.title')}
                  </h1>
                  <AnimatePresence mode="wait">
                      {currentNews?.map((item) => (
                        <NewsItem key={item.id} item={item} />
                        
                      ))}
                      
                  </AnimatePresence>  
                  <Pagination totalPages={Math.ceil(news?.length / newsPerPage)} currentPage={currentPage} paginate={paginate} />
                </main>
            </div>
        </div>
    );
};

export default NewsPage;