/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Authorization.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница авторизации отвечает за отображение страницы авторизации и регистрации.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { NavLink, redirect, useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, REGISTRATION_ROUTE, PIMU_CATEGORIES_ROUTE, PASSWORD_RESTORE_ROUTE, MoodleAuthTypes } from "../utils/consts";
import { login, registration, moodleAuth, getRoles } from "../http/User_API";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import "./Authorization.css"
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import Spline from '@splinetool/react-spline';
import AsyncSelect from 'react-select/async';

import dataUniversities from '../utils/university.json';
import dataSpecialties from '../utils/specialty.json';
import { Spinner } from 'react-bootstrap';

//  Список доступных ролей.
//  TODO: это нужно вынести куда-то на сервер.
const RolesAvailable = [
    //{ value: 'user', label: 'Пользователь' },
    { value: 'student', label: 'Студент' },
    { value: 'teacher', label: 'Преподаватель' },
];

//  Фильтрация списка университетов на основе пользовательского ввода.
const filterUniversity = (data, inputValue) => data.filter( (v) => v.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) );

//  Фильтрация списка специальностей на основе пользовательского ввода.
const filterSpecialty = (data, inputValue) => data.filter( (v) => v.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) );

const rolesOptions = (inputValue, t) => {
    return new Promise( (resolve) =>
        getRoles({ can_select: true }).then( (r) => {
            resolve( r.roles?.map( ({ id, title }) => { return { value: id, label: t(title) }; } ) ?? [] );
        })
    );
};

//  Получение списка университетов.
//  Путь к файлу на сервере: /public/university.json
const universitiesOptions = (inputValue) => {
    return new Promise( (resolve) =>
        resolve(filterUniversity(dataUniversities, inputValue))
    );
};

//  Получение списка специальностей.
//  Путь к файлу на сервере: /public/specialty.json
const specialtyOptions = (inputValue) => {
    return new Promise( (resolve) =>
        resolve(filterSpecialty(dataSpecialties, inputValue))
    );
};

//  Данные для формы авторизации.
const LoginForm = ({ t, error, setError, isBackgroundProcessing, hasBackgroundProcessing }) => {
    //  Авторизация через moodle.
    //  Открывает страницу запроса данных с moodle. Если пользователь авторизован, то вернётся информация о пользователе. Если пользователь не авторизован, то будет открыта страница авторизации, после чего уже будет возвращена информация о пользователе.
    const ProcessMoodleAuth = async (clientId) => {
        try
        {
            if (!clientId)
                throw new Error('Не указан идентификатор клиента');

            console.log(MoodleAuthTypes);

            if (MoodleAuthTypes[clientId] == undefined)
                throw new Error('Неподдерживаемый ресурс авторизации');

            setError(null);

            window.open(new URL('local/oauth/login.php?client_id=' + clientId + '&response_type=code', MoodleAuthTypes[clientId]), '_self');
        }catch (error)
        {
            if (error.message.startsWith('JSON.parse'))
                error.message = 'Не удалось разобрать ответ сервера';

            if (error.message.startsWith('NetworkError'))
                error.message = 'Сервер авторизации недоступен';

            setError(error.message);
        }
    };

    return <>
        <div>
            {t('enter_login')}
            <input disabled={isBackgroundProcessing && 'disabled'} className='input_form' name='email' type='text' autoComplete='on' />
        </div>
        <div>
            <div>
                {t('enter_password')}
                <input disabled={isBackgroundProcessing && 'disabled'} className='input_form' name='password' type='password' autoComplete='off' />
            </div>
        </div>
        <div className="registration_form_hint">
            <div>
                {
                /*
                <a className='authorization_link' href={PASSWORD_RESTORE_ROUTE}>
                    {t('forgot_password')}
                </a>
                <br />
                {t('not_have_account')}
                <a className="authorization_link" href={REGISTRATION_ROUTE}>
                    {t('navbar_title_reg')}
                </a> */
                }
            </div>
            <div className='errors text-danger'>{t(error)}</div>
        </div>
        <input type='submit' disabled={isBackgroundProcessing && 'disabled'} className="authorization_button" value={t('navbar_title_authorization_login')} />
        {/* <button disabled={isBackgroundProcessing && 'disabled'} type='button' className='authorization_button_moodle' onClick={ () => ProcessMoodleAuth('Atlas') }>{t('title_authorization_moodle')}</button>
        <button disabled={isBackgroundProcessing && 'disabled'} type='button' className='authorization_button_moodle' onClick={ () => ProcessMoodleAuth('pimunn') }>{t('title_authorization_moodle_ivanovo')}</button> */}
    </>;
};

//  Данные для формы регистрации.
//  TODO: да, форма сейчас получается слишком "толстая". Возможно, её стоит разделить на шаги, в зависимости от того, какая роль была выбрана?
const RegistrationForm = ({ t, error, setError, isBackgroundProcessing, hasBackgroundProcessing }) => {

    //  Скрыть или показать доп. поля для роли "преподаватель".
    const HandleRoleChange = ({ value, label }) => {
        if (value == 'teacher')
        {
            document.querySelector('#university_select_block').style.display = 'inherit';
            document.querySelector('#specialty_select_block').style.display = 'inherit';
            document.querySelector('#position_select_block').style.display = 'inherit';
        }
        else
        {
            document.querySelector('#university_select_block').style.display = 'none';
            document.querySelector('#specialty_select_block').style.display = 'none';
            document.querySelector('#position_select_block').style.display = 'none';
        }
    };

    if (isBackgroundProcessing)
        return <div style={{ color: '#4f4f4f' }}>
            {error ? <>
                <div className='errors text-danger'>
                    {t(error)}
                </div>
            </> : <>
            <div style={{ textAlign: 'center' }}>
                <Spinner style={{ width: '10rem', height: '10rem' }} />
            </div>
            <div style={{ textAlign: 'center', fontSize: '2rem', marginTop: '2rem' }}>
                {t('loading_text')}
            </div>
            </>}
        </div>;

    return <>
        <div>
            {t('enter_email')}
            <input disabled={isBackgroundProcessing && 'disabled'} className='input_form' name='email' type='text' autoComplete='on' />
        </div>
        <div>
            {t('enter_password')}
            <input disabled={isBackgroundProcessing && 'disabled'} className='input_form' name='password' type='password' autoComplete='off' />
        </div>
        <div>
            {t('enter_registration_code')}
            <input disabled={isBackgroundProcessing && 'disabled'} className='input_form' name='code' type='text' autoComplete='off' />
        </div>
        <div id='role_select_block'>
            {t('enter_role')}
            <AsyncSelect className='input_form select' onChange={HandleRoleChange} translate="yes" cacheOptions placeholder={t('registration_hint_start_search')} defaultOptions={true} loadOptions={ (input) => rolesOptions(input, t) } name='role' disabled={isBackgroundProcessing && 'disabled'} />
        </div>
        <div id='university_select_block' className='mb-2' style={{ display: 'none' }}>
            {t('enter_university')}
            <AsyncSelect className='input_form select' cacheOptions placeholder={t('registration_hint_start_search')} defaultOptions={true} loadOptions={universitiesOptions} name='university' />
            <span style={{ fontSize: '0.9rem' }}>
                {t('registration_hint_no_university')}
            </span>
        </div>
        <div id='specialty_select_block' style={{ display: 'none' }}>
            {t('enter_specialty')}
            <AsyncSelect className='input_form select' cacheOptions placeholder={t('registration_hint_start_search')} defaultOptions={true} loadOptions={specialtyOptions} name='specialty' />
        </div>
        <div id='position_select_block' style={{ display: 'none' }}>
            {t('enter_position')}
            <input type='text' name='position' className='input_form' />
        </div>
        <div className="registration_form_hint">
            <div>
                {t('have_account')}
                <a className="authorization_link"
                    href={isBackgroundProcessing ? '#' : LOGIN_ROUTE}>
                    {t('navbar_title_authorization_login')}
                </a>
            </div>
            <div className='errors text-danger'>{t(error)}</div>
        </div>
        <input type='submit' disabled={isBackgroundProcessing && 'disabled'} className="authorization_button" value={t('navbar_title_reg')} />
    </>;
};

const Authorization = observer(() => {

    // const notify = () => toast("Вы успешно вошли, добро пожаловать в медицинский атлас! Более подробная информация о приложении доступна в разделах О приложении и Поддержка. Если у вас остаются какие-либо вопросы вы можете связаться с нами через раздел Контакты.");
    const { user } = useContext(Context);
    const location = useLocation();
    const history = useNavigate();
    const isLogin = location.pathname === LOGIN_ROUTE;

    const searchParams = new URLSearchParams(location.search);

    const [ error, setError ] = useState(null);
    //  Вспомогательный state чтобы блокировать взаимодействие со страницой на время фоновых задач - авторизация, регистрация.
    const [ isBackgroundProcessing, hasBackgroundProcessing ] = useState(false);

    const { t } = useTranslation();
    const [textIndex, setTextIndex] = useState(0);
    const texts = t('registration_title_text', { returnObjects: true });
    const variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    useEffect(() => {

        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => {
        });

        // Updating scrollSpy when the component mounts.
        scrollSpy.update();

        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
        };
    }, []);

    document.addEventListener('DOMContentLoaded', function () {
        var initialScrollPos = 0;

        window.addEventListener('popstate', function () {
            // Восстанавливаем первоначальную позицию прокрутки при возврате назад
            window.scrollTo({ top: initialScrollPos, behavior: 'instant' });
        });
    });

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    useEffect( () => {
        //  Если мы пришли на эту страницу со страницы авторизации Moodle, у нас есть параметр 'code' - то можно зарегистрировать пользователя.
        if (!isLogin && searchParams.has('code') && searchParams.get('code') != null)
        {
            hasBackgroundProcessing(true);
            moodleAuth(searchParams.get('code'), searchParams.get('clientid')).then( (d) => {
                if (!d?.result)
                {
                    //  Авторизация не удалась.
                    setError(d.message);
                    return;
                }else{
                    //  Пользователь выполнил вход.
                    setError(null);
                    hasBackgroundProcessing(false);

                    user.setProfile(d.user);
                    user.setAccess_level(d.user.access_level);
                    user.setUser(user);
                    user.setIsAuth(true);
                    user.setCounters({});

                    history(PIMU_CATEGORIES_ROUTE);
                }
            });
        }
    }, []);

    //  Общая функция для отправки запроса на авторизацию или регистрацию.
    const HandleLogin = async (event) => {
        event.preventDefault();

        setError(null);
        hasBackgroundProcessing(true);

        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        try {
            if (!requestData.email)
                throw new Error('auth_form_not_filled');

            if (!isLogin)
            {
                if (!requestData.role)
                    throw new Error('auth_form_not_filled');

                if (requestData.role == 'teacher' && (!requestData.university || !requestData.specialty || !requestData.position))
                    throw new Error('auth_form_not_filled');
            }

            const response = isLogin ?
                ( await login(requestData.email, requestData.password) ) :
                ( await registration(requestData) );

            if (!response || response?.error)
                throw new Error(response.error ?? 'auth_fetch_error');

            setError(null);
            hasBackgroundProcessing(false);

            user.setProfile(response.user);
            user.setAccess_level(response.user.access_level);
            user.setUser(user);
            user.setIsAuth(true);
            user.setCounters({});
            history(PIMU_CATEGORIES_ROUTE);
        } catch (e) {
            setError(e.message);
            hasBackgroundProcessing(false);
        }
    }

    return (
        <div className='registration_page'>
            <div className='registration_grid'>
                <div className='registration_title'>
                    <b>{t('start_page_title')}</b> • {isLogin ? t('navbar_title_authorization_login') : t('navbar_title_reg')}
                </div>
                <div className='registration_title_text'>
                    <AnimatePresence mode='wait'>
                        <motion.div
                            key={texts[textIndex]}
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 1 }}
                            onAnimationComplete={() => {
                                setTimeout(() => {
                                    setTextIndex((textIndex + 1) % texts.length);
                                }, 3500); // Задержка перед переключением на следующий текст
                            }}
                        >
                            {texts[textIndex]}
                        </motion.div>
                    </AnimatePresence>
                </div>
                <div className='spline_scene_demo_reg'>
                    <Spline scene="https://prod.spline.design/94EWGQjymKY1dAjW/scene.splinecode" />
                </div>
                <div className="registration_form">
                    <div className="registration_form_welcome">
                        {t('welcome_reg')}
                    </div>
                    <div className="registration_form_input">
                        <form onSubmit={HandleLogin}>
                            {isLogin
                                ? <LoginForm t={t} error={error} setError={setError} isBackgroundProcessing={isBackgroundProcessing} hasBackgroundProcessing={hasBackgroundProcessing} />
                                : <RegistrationForm t={t} error={error} setError={setError} isBackgroundProcessing={isBackgroundProcessing} hasBackgroundProcessing={hasBackgroundProcessing} />
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Authorization;
